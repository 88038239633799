import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, Circle } from "@react-google-maps/api";
import { CustomViewModal } from "../../Utils/Inputs";

const ProfileLocation = ({ show, onClose, sharedData }) => {
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

  const lat = sharedData?.geo?.lat || 0; // Default to 0 to prevent issues
  const lng = sharedData?.geo?.lng || 0;

  const center = { lat: +lat, lng: +lng };

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    if (window.google) {
      setIsGoogleLoaded(true);
    } else {
      const interval = setInterval(() => {
        if (window.google) {
          setIsGoogleLoaded(true);
          clearInterval(interval);
        }
      }, 100);
    }
  }, []);

  if (!isGoogleLoaded) return <div>Loading...</div>;

  return (
    <CustomViewModal heading="Location" show={show} onClose={onClose} size="lg">
      <div style={{ maxHeight: "70vh", height: "70vh" }}>
        <GoogleMap mapContainerStyle={mapContainerStyle} zoom={17} center={center}>
          {/* Marker at the location */}
          <Marker position={center} />

          {/* Circle around the location */}
          <Circle
            center={center}
            radius={100}
            options={{
              strokeOpacity: 0.8,
              strokeWeight: 0.7,
              fillColor: "#add8e6",
              fillOpacity: 0.45,
            }}
          />
        </GoogleMap>
      </div>
    </CustomViewModal>
  );
};

export default ProfileLocation;