import React, { useEffect, useState } from "react";
import { GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import { CustomViewModal } from "../Utils/Inputs";

const Geofence = ({ show, onClose, sharedData }) => {
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);

  const center = { lat: sharedData?.lat || 0, lng: sharedData?.lng || 0 };
  const coordinates =
    sharedData?.geofence && sharedData.geofence[0] && sharedData.geofence[0].geofence.coordinates
      ? sharedData.geofence[0].geofence.coordinates.map((x) => ({
          lat: x[1],
          lng: x[0],
        }))
      : [];
  // console.log(coordinates, center);

  useEffect(() => {
    if (window.google) {
      setIsGoogleLoaded(true);
    } else {
      const interval = setInterval(() => {
        if (window.google) {
          setIsGoogleLoaded(true);
          clearInterval(interval);
        }
      }, 100);
    }
  }, []);

  if (!isGoogleLoaded) return <div>Loading...</div>;

  // Calculate the geofence center dynamically
  const calculateCenter = (coords) => {
    if (coords.length === 0) return center;
    const lats = coords.map((coord) => coord.lat);
    const lngs = coords.map((coord) => coord.lng);
    return {
      lat: (Math.max(...lats) + Math.min(...lats)) / 2,
      lng: (Math.max(...lngs) + Math.min(...lngs)) / 2,
    };
  };

  const geofenceCenter = calculateCenter(coordinates);


  // console.log('------>>', geofenceCenter);

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <CustomViewModal heading="Geofence" show={show} onClose={onClose} size="lg">
      <div style={{ maxHeight: "70vh", height: "70vh" }}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={17}
          center={geofenceCenter}
        >
          {/* Draw the geofence polygon */}
          <Polygon
            paths={coordinates}
            options={{
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillColor: "#FF0000",
              fillOpacity: 0.34,
            }}
          />

          {/* Marker at the center of the geofence */}
          <Marker position={geofenceCenter} />
        </GoogleMap>
      </div>
    </CustomViewModal>
  );
};

export default Geofence;
