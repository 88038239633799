import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { getCashMemoListing } from '../../state/redux/actions/index';
import CustomPageLayout from '../Utils/CustomPageLayout/CustomPageLayout';
import { InputSearchDropDown, InputSearch, Input, CustomTable, showNotification } from '../Utils/Inputs';
import { handlePostApi } from '../../services/functions';


const CashMemoC = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.cashMemoListingRed.cashListing)
    const loader = useSelector((state) => state.cashMemoListingRed.loading)
    const count = useSelector((state) => state.cashMemoListingRed.count)
    const [pageLimit, setPageLimit] = useState(10)
    const [pageCount, setPageCount] = useState(1)
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: 'consumerName',
        searchValue: '',
        startDate: '',
        endDate: ''
    })

    useEffect(() => {
        dispatch(getCashMemoListing({
            page: pageCount,
            limit: pageLimit,
            searchType: '',
            searchValue: '',
            startDate: '',
            endDate: ''
        }))
    }, [dispatch,])

    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = (action) => {
        if (action === "clear") {
          dispatch(
            getCashMemoListing({
              page: 1,
              limit: 10,
              searchType: searchObj.searchType,
              searchValue: "",
              startDate: searchObj.startDate,
              endDate: searchObj.endDate,
            })
          );
        } else {
          setPageCount(1);
          dispatch(
            getCashMemoListing({
              page: 1,
              limit: pageLimit,
              searchType: searchObj.searchType,
              searchValue: searchObj.searchValue,
              startDate: searchObj.startDate,
              endDate: searchObj.endDate,
            })
          );
        }
    }
    
    const cashMemoFunction = async (item) => {
      let data = { fileName: item.filepath };
      try {
        const result = await handlePostApi("/general/getImageToBlob", { ...data });
        if (result.success) {
          showNotification({ message: result.message, title: "Success!!", autohide: true, showInput: false });
          let base = result.data.data;
          let blob = b64toBlob(base, "application/pdf");
          let ur = URL.createObjectURL(blob);
          setTimeout(() => {
            window.open(ur, "_blank");
          }, 100);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };
    

    const tableHeader = ["S. No", "BDA Code", "Consumer Name", "Mobile Number", "Cash Memo Date & Time", "Action"];
      const tableRows =
        (listing &&
          listing.map((item, index) => {
            let items = [];
            items.push(
              <>{(pageCount - 1) * pageLimit + index + 1}</>,
              <>{item?.bdaCode || "-"}</>,
              <>{item?.consumerName || "-"}</>,
              <>{item?.consumerMobileno || "-"}</>,

              <>
                {item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY") : "-"} , {item.createdAt ? moment(item.createdAt).format("h:mm a") : "-"}
              </>,

              <>
                {" "}
                <button className="cashMemoBtn" 
                onClick={() => cashMemoFunction(item)}
                >
                  Cash Memo <i className="fa fa-download ml-1" aria-hidden="true"></i>
                </button>
              </>
            );
    
            return items;
          })) ||
        [];

        const handlePagination = (page, pageLimitt) => {
          if (page) {
            setPageCount(page);
            dispatch(
              getCashMemoListing({
                page: page,
                limit: pageLimit,
                searchType: "",
                searchValue: "",
              })
            );
          }
          if (pageLimitt) {
            setPageCount(1);
            setPageLimit(pageLimitt);
            dispatch(
              getCashMemoListing({
                page: 1,
                limit: pageLimitt,
                searchType: "",
                searchValue: "",
              })
            );
          }
          // console.log("handlePagination", page, pageLimit);
        };
    return (
      <CustomPageLayout title="Cash Memo">
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          <div className="searcBox alignCen">
            <InputSearchDropDown
              type="text"
              label="Filter Search"
              name="searchValue"
              value={searchObj.searchValue}
              onChange={(e) => _handleChange(e)}
              placeholder="Search here..."
              style={{ zIndex: "10" }}
              onSubmit={() => triggerSearch("submit")}
              selectOptions={[
                { label: "Consumer Name", value: "consumerName" },
                { label: "BDA code", value: "bdaCode" },
              ]}
              selectValue={searchObj.searchType}
              onSelectChange={(item) => {
                setSearchObject({ ...searchObj, searchType: item.target.value, searchValue: "" });
              }}
              onClear={() => {
                setSearchObject({ ...searchObj, searchValue: "" });
                setPageCount(1);
                setPageLimit(10);
                triggerSearch("clear");
              }}
            />
          </div>
          <div className="searcBox alignCen">
            <Input type="date" name="startDate" value={searchObj.startDate} label="Start Date" onChange={(e) => _handleChange(e)} />
          </div>
          <div className="searcBox alignCen">
            <InputSearch
              type="date"
              label="End Date"
              name="endDate"
              value={searchObj.endDate}
              onChange={(e) => _handleChange(e)}
              placeholder="Search here..."
              style={{ zIndex: "10" }}
              buttonDisabled={!searchObj.endDate || !searchObj.startDate ? true : false}
              onSubmit={() => triggerSearch("submit")}
            />
          </div>
        </div>
        <div>
          <CustomTable
              activePage={pageCount}
              pageLimit={pageLimit}
              count={Math.ceil(count / pageLimit)}
              handlePagination={(page, pageLimit) => handlePagination(page, pageLimit)}
            header={tableHeader}
            rows={tableRows ? tableRows : []}
            loading={loader}
          />
        </div>
      </CustomPageLayout>
      // <div className="contentpanel">
      //     <div className="cust-row flex-algn-cent">
      //         <div className='cust-col-5'>
      //             <h1 className="title">
      //                 Cash Memo
      //             </h1>
      //         </div>
      //         <div className='cust-col-5 flex-jc-end'>
      //             <h1 className="title font-bold orange_color">
      //             </h1>
      //         </div>
      //     </div>
      //     <hr className='bgg' />
      //     <div className="cust-container">
      //         <div class="cust-row">
      //             <div class="cust-col-10">
      //                 <div className='bdaOuter' >
      //                     <div className='searcBox alignCen' style={{width:'auto'}}>
      //                         <div className='cust-col-4-new'>
      //                             <div className="input-group">
      //                                 <div className="input-group-prepend" style={{ width: '30%', zIndex: '10', marginRight: '15px' }}>
      //                                     <select className="select2-single" defaultValue="select" id="cashMemoSearch">
      //                                         <option value="select" disabled>Select</option>
      //                                         <option value="consumerName">Consumer Name</option>
      //                                         <option value="bdaCode">BDA code</option>
      //                                     </select>
      //                                 </div>
      //                                 <input type="text" name='searchValue' value={searchObj.searchValue} onChange={(e) => _handleChange(e)} className="form-control" placeholder="Search here..." style={{ zIndex: '10' }} />
      //                                 <div className="input-group-append" onClick={() => triggerSearch()}>
      //                                     <button type="submit" className="btn btn-orange p-1 pl-3 pr-3 pt-2">
      //                                         <i className="fa fa-search"></i>
      //                                     </button>
      //                                 </div>
      //                             </div>
      //                         </div>
      //                     </div>

      //                     <div className='twoCalend'>
      //                         <div className='firstCalenBox' style={{marginRight:'3px'}}>
      //                             <span className='calenSpan'>Start Date</span>
      //                             <input className='twodateInput' type='date' onChange={(e) => _handleDateChange(e,'start')} />
      //                         </div>
      //                         <div className='firstCalenBox'>
      //                             <span className='calenSpan'>End Date</span>
      //                             <input className='twodateInput' type='date' onChange={(e) => _handleDateChange(e,'end')} />
      //                         </div>

      //                         <div className="input-group-append" >
      //                             <button type="submit" onClick={() => search()} className="btn btn-orange p-1 pl-3 pr-3 pt-2"  disabled={searchObj.startDate === '' || searchObj.endDate === '' ? true : false}>
      //                                 <i className="fa fa-search"></i>
      //                             </button>
      //                         </div>
      //                     </div>
      //                 </div>

      //                 {loader === false ?
      //                     listing && listing.length !== 0 ?
      //                         <div className="generic-table-wrapper mt20">
      //                             <Table className="generic-table">
      //                                 <thead className="bg-accent">
      //                                     <tr>
      //                                         <th>S. No</th>
      //                                         <th>BDA Code</th>
      //                                         <th>Consumer Name</th>
      //                                         <th>Mobile Number</th>
      //                                         <th>Cash Memo Date & Time</th>
      //                                         <th>Action</th>
      //                                     </tr>
      //                                 </thead>
      //                                 <tbody className="bg-gray-100">
      //                                     {listing && listing.map((item, index) => {
      //                                         return (
      //                                             <tr key={index}>
      //                                                 <td style={{ color: '#007BC9' }}>
      //                                                     {((pageCount - 1) * (pageLimit)) + index + 1}
      //                                                 </td>
      //                                                 <td>{item?.bdaCode|| '-'}</td>
      //                                                 <td>{item?.consumerName || '-'}</td>
      //                                                 <td >{item?.consumerMobileno || "-"}</td>

      //                                                 <td>{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY") : '-'} , {item.createdAt ? moment(item.createdAt).format("h:mm a") : '-'}
      //                                                 </td>

      //                                                 <td> <button className='cashMemoBtn' onClick={() => cashMemoFunction(item)}>
      //                                                     Cash Memo <i className="fa fa-download ml-1" aria-hidden="true"></i>
      //                                                 </button></td>
      //                                             </tr>
      //                                         )
      //                                     })}
      //                                 </tbody>
      //                             </Table>
      //                         </div>
      //                         :
      //                         <div className='noData'>
      //                             <p>{message}</p>
      //                         </div>
      //                     :
      //                     <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
      //                         <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
      //                     </div>

      //                 }

      //                 {listing && listing.length !== 0 ?
      //                     <div className='paginationDiv'>
      //                         <div className='entries'>
      //                             <span className='entrieSpan'>Show Entries :</span>
      //                             <Select
      //                                 onChange={(value) => handleChange(value)}
      //                                 options={entries}
      //                                 styles={colourStyles}
      //                             />
      //                         </div>
      //                         <button disabled={pageCount === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
      //                         <button disabled={Math.floor((count + pageLimit - 1) / pageLimit) === pageCount ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
      //                     </div>
      //                     : ''}
      //             </div>
      //         </div>
      //     </div>

      //     {/* <Geofence closeModal={_closeModal} geo={geo} /> */}
      // </div>
    );
};

export default CashMemoC;