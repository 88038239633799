import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import './FeedBack.css';
import { useDispatch, useSelector } from 'react-redux'
import { getfeedbackData } from '../../state/redux/actions/feedback';
import axios from 'axios'
import { logoutOnExpiry, url } from '../../services/requests'

const feedbackRatingOption = [
    {
        "_id": "66c86b72e4f2ede026f310b0",
        "ratingNumber": "1",
        "optionList": [
            "Delivery boy not in uniform",
            "Home Delivery Not done",
            "Delivery boy demanded more money than selling Price",
            "Bad Behaviour by delivery boy",
            "Leakage from LPG cylinder",
            "Weight of LPG in cylinder less than 14.2 kg",
            "Others"
        ]
    },
    {
        "_id": "66c86b72e4f2ede026f310b1",
        "ratingNumber": "2",
        "optionList": [
            "Delivery boy not in uniform",
            "Home Delivery Not done",
            "Delivery boy demanded more money than selling Price",
            "Bad Behaviour by delivery boy",
            "Leakage from LPG cylinder",
            "Weight of LPG in cylinder less than 14.2 kg",
            "Others"
        ]
    },
    {
        "_id": "66c86b72e4f2ede026f310b2",
        "ratingNumber": "3",
        "optionList": [
            "Delivery boy not in uniform",
            "Home Delivery Not done",
            "Delivery boy demanded more money than selling Price",
            "Bad Behaviour by delivery boy",
            "Leakage from LPG cylinder",
            "Weight of LPG in cylinder less than 14.2 kg",
            "Others"
        ]
    },
    {
        "_id": "66c86b72e4f2ede026f310b3",
        "ratingNumber": "4",
        "optionList": [
            "Good quality of cylinder",
            "Well behaved delivery boy",
            "Cylinder home delivered",
            "Safety tips given by Delivery boy",
            "Delivery boy connected cylinder with Gas stove",
            "Cylinder delivered in time",
            "Others"
        ]
    },
    {
        "_id": "66c86b72e4f2ede026f310b4",
        "ratingNumber": "5",
        "optionList": [
            "Good quality of cylinder",
            "Well behaved delivery boy",
            "Cylinder home delivered",
            "Safety tips given by Delivery boy",
            "Delivery boy connected cylinder with Gas stove",
            "Cylinder delivered in time",
            "Others"
        ]
    }
]

export default function FeedBack() {
    const dispatch = useDispatch();
    const { feedbackId } = useParams();
    const [alreadySubmitted, setAlreadySubmitted] = useState(false);
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const [feedbackReason, setFeedbackReason] = useState([]);
    const [error, setError] = useState({
        rating: false,
        feedbackReason: false,
        feedback: false
    });
    const feedbackRatingOption = useSelector((state) => state.getFeedbackRed?.feedbackData?.feedbackOptions) || [];
    const feedbackMessage = useSelector((state) => state.getFeedbackRed.message);

    // console.log(feedbackId, feedbackRatingOption, feedbackMessage)



    const handleInputChange = (e) => {
        const input = e.target.value;
        const words = input.trim().split(/\s+/);
        const count = words.length;

        if (count <= 100) {
            setFeedback(input);
            setWordCount(count);
        }
        if (e.target.value === "") {
            setWordCount(0);
        }
    };

    const handleRating = (index) => {
        setRating(index);
        setFeedbackReason([]);
        setFeedback('');
        setWordCount(0);
        setError({
            rating: false,
            feedbackReason: false,
            feedback: false
        });
    };

    const validation = () => {
        const newError = { ...error };
        let isValid = true;

        if (rating === 0) {
            newError.rating = true;
            isValid = false;
        } else {
            newError.rating = false;
        }

        if (rating !== 0 && feedbackReason.length === 0) {
            newError.feedbackReason = true;
            isValid = false;
        } else {
            newError.feedbackReason = false;
        }

        if (feedbackReason.includes('Others') && feedback === '') {
            newError.feedback = true;
            isValid = false;
        } else {
            newError.feedback = false;
        }

        setError(newError);

        return isValid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log({ rating, feedbackReason });
        const isValid = validation();
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        let temp = {
            "_id": feedbackId,
            "ratingNumber": rating,
            "reasons": feedbackReason,
            "comment": feedback
        }
        if (isValid) {
            axios.post(`${url}/user/submitFeedback`, { ...temp }, { headers: config.headers })
                .then(response => {
                    const status = response.data.success
                    if (status === true) {
                        setAlreadySubmitted(true);
                    } else {
                        window.$.fn.show_notification({ message: 'Something went wrong', title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                            if (result.type === 'confirm') {
                            }
                        })
                        // window.$.fn.show_notification({ message: 'Something went wrong', title: 'Error!!', autohide: true, showInput: false })
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
        }

    };

    useEffect(() => {
        if (feedbackRatingOption?.length > 0) {
            setAlreadySubmitted(false)
        } else {
            if (feedbackMessage == "Feedback already submitted or link has been expired") {
                setAlreadySubmitted(true)
            }
            if (feedbackMessage == 'Invalid request.') {
                window.$.fn.show_notification({ message: `${feedbackMessage}`, title: 'Oops!!', autohide: true, showInput: false }, (result) => {
                    if (result.type === 'confirm') {
                    }
                })
                setTimeout(() => {
                    logoutOnExpiry()
                }, 1000)
            }
            setAlreadySubmitted(true)
        }
    }, [feedbackRatingOption])

    useEffect(() => {
        if (feedbackId) {
            const payload = {
                "_id": feedbackId,
                "type": "feedbackFromCustomer"
            }
            dispatch(getfeedbackData(payload))
        }

    }, [feedbackId])
    return (
        <div className='Feedback-container'>
            {!feedbackMessage ? "" : (!alreadySubmitted ? <div className="feedback-form-container">
                <h2 className="feedback-title">Give us feedback</h2>
                <p className="feedback-subtitle">Please rate the performance of our application and leave feedback below</p>

                <div className="stars">
                    {[1, 2, 3, 4, 5].map((index) => (
                        <span
                            key={index}
                            className={`star ${index <= rating ? 'selected' : ''}`}
                            onClick={() => handleRating(index)}
                        >
                            &#9733;
                        </span>
                    ))}
                </div>
                <p className='errorText'>{error.rating && 'Rating is required*'}</p>

                <div className="anonymous-checkbox">
                    {feedbackRatingOption?.length > 0 && feedbackRatingOption?.filter((ele) => ele.ratingNumber == rating)[0]?.optionList?.map((ele, index) => (
                        // <label className="radio-label" key={ele}>
                        //     <input type="radio" name={ele} id={ele} checked={feedbackReason === ele ? true : false} onChange={(e) => setFeedbackReason(ele)} />
                        //     <span className="custom-radio"></span>
                        //     {ele}
                        // </label>
                        <label className="radio-label" key={ele}>
                            <input
                                type="checkbox"
                                name={ele}
                                id={ele}
                                checked={feedbackReason.includes(ele)}
                                onChange={(e) => {
                                    setError({
                                        rating: false,
                                        feedbackReason: false,
                                        feedback: false
                                    });

                                    setFeedbackReason((prevs) => {
                                        if (e.target.name === 'Others') {
                                            // If "Others" is checked, clear all other selections and keep only "Others"
                                            return e.target.checked ? ['Others'] : [];
                                        } else {
                                            if (e.target.checked) {
                                                // Add the current item to the list, but remove "Others" if present
                                                return [...prevs.filter((el) => el !== 'Others'), ele];
                                            } else {
                                                // Remove the current item from the list
                                                return prevs.filter((el) => el !== ele);
                                            }
                                        }
                                    });
                                }}
                            />
                            <span className="custom-checkbox"></span>
                            {ele}
                        </label>
                    ))}
                    <p className='errorText'>{error.feedbackReason && 'Select atleast one reason is required*'}</p>
                </div>
                {feedbackReason.includes('Others') && <><textarea
                    className="feedback-input"
                    placeholder="Your feedback (optional)"
                    maxLength="300"
                    value={feedback}
                    onChange={handleInputChange}
                />
                    <p className='errorText'>{error.feedback && 'Feedback is required*'}</p>
                    <p className="char-limit">Max {wordCount}/ 100 characters.</p>
                </>}

                <button className="submit-button" onClick={handleSubmit}>
                    SUBMIT
                </button>
            </div>
                :
                <div className="feedback-Thankyou-form-container">
                    <CheckBadge />
                    <br />
                    <h2 className="feedback-title">Thank you for your feedback.</h2>
                </div>)
            }

        </div>
    )
}




const CheckBadge = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 256 256">
            <path fill="green" d="M225.86 102.82c-3.77-3.94-7.67-8-9.14-11.57c-1.36-3.27-1.44-8.69-1.52-13.94c-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52c-3.56-1.47-7.63-5.37-11.57-9.14C146.28 23.51 138.44 16 128 16s-18.27 7.51-25.18 14.14c-3.94 3.77-8 7.67-11.57 9.14c-3.25 1.36-8.69 1.44-13.94 1.52c-9.76.15-20.82.31-28.51 8s-7.8 18.75-8 28.51c-.08 5.25-.16 10.67-1.52 13.94c-1.47 3.56-5.37 7.63-9.14 11.57C23.51 109.72 16 117.56 16 128s7.51 18.27 14.14 25.18c3.77 3.94 7.67 8 9.14 11.57c1.36 3.27 1.44 8.69 1.52 13.94c.15 9.76.31 20.82 8 28.51s18.75 7.85 28.51 8c5.25.08 10.67.16 13.94 1.52c3.56 1.47 7.63 5.37 11.57 9.14c6.9 6.63 14.74 14.14 25.18 14.14s18.27-7.51 25.18-14.14c3.94-3.77 8-7.67 11.57-9.14c3.27-1.36 8.69-1.44 13.94-1.52c9.76-.15 20.82-.31 28.51-8s7.85-18.75 8-28.51c.08-5.25.16-10.67 1.52-13.94c1.47-3.56 5.37-7.63 9.14-11.57c6.63-6.9 14.14-14.74 14.14-25.18s-7.51-18.27-14.14-25.18m-52.2 6.84l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 11.32" />
        </svg>
    )
}
