/* eslint-disable */
import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import axios from 'axios'
import history from '../../services/history';
import customMarker from './../../static/images/icon/sidebar/updated/marker.png'
import gps from './../../static/images/icon/sidebar/updated/gps.png'
import pin from './../../static/images/icon/sidebar/updated/pin.png'
import truck from './../../static/images/icon/sidebar/updated/truckAnimated.gif'
import moment from 'moment'
import truckNew from './../../static/images/icon/sidebar/updated/cylinderdelivery.png'
import redFlag from './../../static/images/icon/sidebar/updated/red-flag.png'
import solidblue from './../../static/images/icon/sidebar/updated/solidblue.png'
import { logoutOnExpiry, url } from './../../services/requests'
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';

const TripMap = (props) => {
    const [timeSlots, setTimeSlots] = useState({})
    const [trackData, setTrackData] = useState({})
    const dummyData = props.location.state
    let colorCodes = ['#ff0054', '#191970', 'purple', 'brown', 'red', 'brown', 'green', 'blue', '#007BC9', 'tomato', '#225079', '#505b72', '#800080', '#B9D874', 'peru', 'palegreen', 'olive', 'hotpink', 'darkred', 'darkblue', 'lime']
    let markersArray = [redFlag, solidblue]
    const closeSideBar = () => {
        $(".positionsElem").removeClass('slide-in')
        $('.positionsElem').addClass('slide-out')
    }

    const _moveBck = () => {
        history.goBack()
    }
    const toggleView = () => {
        if ($(".positionsElem").hasClass('slide-out')) {
            $(".positionsElem").removeClass('slide-out')
            $('.positionsElem').addClass('slide-in')
        }
        else {
            $('.positionsElem').addClass('slide-out')
            $(".positionsElem").removeClass('slide-in')
        }
    }
    const refreshEta = () => {
        const token = localStorage.getItem('secretkey')
        const config = {
            headers: { Authorization: token }
        };
        axios.post(`${url}/trip/getETA`, { tripId: dummyData.tripId }, { headers: config.headers })
            .then(response => {
                const data = response.data.data
                const status = response.data.success
                if (status === true) {
                    $(".custom-modal-overlay").removeClass("custom-modal-is-visible")
                    $(".custom-modal").removeClass("custom-modal-is-visible")
                    let tmp = data && data.locations ? data.locations : ''
                    for (let i = 0; i < tmp.length; i++) {
                        let duration = 'locationDetails' in tmp[i] ? tmp[i].locationDetails.eta : ''
                        // let duration = 'locationDetails' in tmp[i] ? 90 : ''
                        if (duration) {
                            // let minutes = Math.floor((duration) % 60);
                            // let hours = Math.floor((duration / (60)) % 24);
                            // hours = (hours < 10) ? "0" + hours : hours;
                            // minutes = (minutes < 10) ? "0" + minutes : minutes;
                            // let dum = `${hours}   Hours   ${minutes}  minutes`
                            // let fake = { ...a }
                            // fake.locations[i].locationDetails.eta = dum

                            let finalTime = ""
                            let time = duration / 60
                            if (time > 60) {
                                let t = time / 60
                                let minute = (t - Math.floor(t)) * 60
                                if (minute > 0) {
                                    minute = 1
                                }
                                finalTime = finalTime + Math.floor(t) + " hours " + minute + " minutes"
                            }
                            else {
                                finalTime = finalTime + Math.trunc(time) + " minutes";
                            }
                            let local = { ...timeSlots }
                            local.locations[i].locationDetails.eta = finalTime
                            setTimeSlots(local)
                        }

                    }
                    window.$.fn.show_notification({ message: 'Successfully Fetched..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
                else {
                    window.$.fn.show_notification({ message: response?.data?.message || 'Error in fetching..', title: 'Message!!', autohide: true, showInput: false }, (result) => {
                        if (result.type === 'confirm') {
                        }
                    })
                }
            })
            .catch(error => {
                console.log('error', error);
                if (error.error.status === 401) {
                    logoutOnExpiry()
                }
            })
    }
    useEffect(() => {
        var mapOptions = {
            center: new google.maps.LatLng(dummyData.locations[0].location[1], dummyData.locations[0].location[0]),
            zoom: 16,
            // mapTypeId: google.maps.MapTypeId.ROADMAP
        }
        var mapElement = document.getElementById('myMap');
        var map = new google.maps.Map(mapElement, mapOptions)
        // let waypoints = [];
        let sortedLatLng = dummyData.locations
        let dummy = [...sortedLatLng]
        let vehNumber = dummyData.vehicle.vehicleNo
        var marker;
        let char = 'A';
        var currentCode = 0
        var num = 0
        var infowindow = new google.maps.InfoWindow({ map: map });
        let coords = []
        var oms = new OverlappingMarkerSpiderfier(map, {
            markersWontMove: true,
            markersWontHide: true,
            basicFormatEvents: true,
            keepSpiderfied: false,
            circleSpiralSwitchover: 0,
            legWeight: 6,
            legColor: 'red',
            circleFootSeparation: 40,
            spiralFootSeparation: 30,
            nudgeStackedMarkers: true,
            spiderfiedShadowColor: "#1E90FF"
        });
        dummy.forEach((ele, index) => {
            let lat = ele.isDelivered ? ele?.deliveryLocation?.latitude : ele?.location[1]
            let lng = ele.isDelivered ? ele?.deliveryLocation?.longitude : ele?.location[0]
            let tmp = {
                lat: lat,
                lng: lng,
                label: (ele?.locationType === "source" || ele?.locationType === "destination") ? "S" : (index).toString().toUpperCase(), // label: currentCode < 26 ? String.fromCharCode(char.charCodeAt(0) + currentCode) : String.fromCharCode(char.charCodeAt(0) + 0) + String.fromCharCode(char.charCodeAt(0) + num + 1),
            }
            currentCode < 26 ? currentCode++ : num++;
            coords.push(tmp)
        })

        const directionsService = new google.maps.DirectionsService();
        for (var i = 0, parts = [], max = 25 - 1; i < coords.length; i = i + max)
            parts.push(coords.slice(i, i + max + 1));
        for (var i = 0; i < parts.length; i++) {
            var waypoints = [];
            for (var j = 1; j < parts[i].length - 1; j++)
                waypoints.push({ location: parts[i][j], stopover: false });
            var service_options = {
                origin: parts[i][0],
                destination: parts[i][parts[i].length - 1],
                waypoints: waypoints,
                travelMode: 'DRIVING'
            };
            directionsService.route(service_options, function (result, status) {
                if (status === 'OK') {
                    var renderer = new google.maps.DirectionsRenderer;
                    renderer.setMap(map);
                    renderer.setOptions({ suppressMarkers: true, preserveViewport: true, polylineOptions: { strokeColor: '#ff7200' } });
                    renderer.setDirections(result);
                }
                else {
                    var failedArray = []
                    var failedOriginLat = result.request.origin.location.lat()
                    var failedOriginLng = result.request.origin.location.lng()
                    var failedDestinationLat = result.request.origin.location.lat()
                    var failedDestinationLng = result.request.origin.location.lng()
                    var failedWayPoints = result.request.waypoints
                    for (var n = 0; n < failedWayPoints.length; n++) {
                        failedArray.push({
                            lat: failedWayPoints[n].location.location.lat(),
                            lng: failedWayPoints[n].location.location.lng()
                        })
                    }
                    failedArray.unshift({ lat: failedOriginLat, lng: failedOriginLng })
                    failedArray.push({ lat: failedDestinationLat, lng: failedDestinationLng })
                    for (let p = 0; p < failedArray.length; p++) {
                        const flightPath = new google.maps.Polyline({
                            path: [failedArray[p], failedArray[p + 1]],
                            geodesic: true,
                            strokeColor: colorCodes[i],
                            strokeOpacity: 1.0,
                            strokeWeight: 5,
                        });
                        flightPath.setMap(map);
                    }
                }
            });
        }
        for (let num = 0; num < dummyData.locations.length; num++) {
            let duration = dummyData && dummyData.locations && dummyData.locations[num].locationDetails && dummyData.locations[num].locationDetails && dummyData.locations[num].locationDetails.eta ? dummyData.locations[num].locationDetails.eta : 0
            let lat = dummyData?.locations[num]?.isDelivered ? dummyData?.locations[num]?.deliveryLocation?.latitude : dummyData.locations[num].location[1]
            let lng = dummyData?.locations[num]?.isDelivered ? dummyData?.locations[num]?.deliveryLocation?.longitude : dummyData.locations[num].location[0]
            if (duration) {
                // let finalTime = ""
                // let time = duration / 60
                // if (time > 60) {
                //     let t = time / 60
                //     let minute = (t - Math.floor(t)) * 60
                //     if (minute > 0) {
                //         minute = 1
                //     }
                //     finalTime = finalTime + Math.floor(t) + " hours " + minute + " minutes"
                // }
                // else {
                //     finalTime = finalTime + Math.trunc(time) + " minutes";
                // }
                const hours = Math.floor(duration / 3600);
                const minutes = Math.floor((duration % 3600) / 60);
                const seconds = Math.floor(duration % 60);

                let finalTime = "";

                if (hours > 0) {
                  finalTime += `${hours} hour${hours !== 1 ? "s" : ""} `;
                }
                if (minutes > 0 || hours > 0) {
                  finalTime += `${minutes} min${minutes !== 1 ? "s" : ""} `;
                }
                finalTime += `${seconds > 0 ? `${seconds}s` : ""}`;
                let local = { ...dummyData }
                local.locations[num].locationDetails.eta = finalTime
                setTimeSlots(local)
            }
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(lat, lng),
                map: map,
                icon: {
                    url: dummyData.locations[num].isReached === true ? redFlag : solidblue,
                    scaledSize: new google.maps.Size(38, 38),
                    labelOrigin: new google.maps.Point(19, 13)
                },
                label: {
                    text: coords[num].label,
                    color: "#fff",
                    fontSize: "10px",
                    fontWeight: '800',
                }
            });
            // oms.addMarker(marker);
            var data = dummyData?.locations[num]?.locationDetails;
            const info = data && data?.personName && data?.mobile ? `<p id="infoSpan">${data?.personName} , ${data?.mobile}</p>` : ''
            if (data) {
                google.maps.event.addListener(marker, 'click', (function (data, marker) {
                    return function () {
                        oms.addListener('click', function (marker, event) {
                            infowindow.setContent('<div id="content" class="infoWindow" >' +
                                '<div id="siteNotice">' +
                                "</div>" +
                                '<h5 id="firstHeading" class="firstHeading">Details</h5>' +
                                '<div id="bodyContent">' +
                                `<p id="infoSpan">${data.address}</p>` +
                                "</div>" +
                                '<div id="bodyContent">' +
                                `<p id="infoSpan">${info}</p>` +
                                "</div>" +
                                "</div>");
                            infowindow.open(map, marker);
                        });
                        google.maps.event.addListener(infowindow, 'closeclick', () => {
                            // handlePopupAndDropdownClose()
                        });
                        oms.addMarker(marker);
                    };
                })(data, marker));
                
            }
            // if (info && data) {
            //     google.maps.event.addListener(marker, 'click', (function (data, marker) {
            //         return function () {
            //             setTimeout(() => {
            //                 oms.addListener('click', function (marker, event) {
            //                     infowindow.setContent('<div id="content" class="infoWindow" >' +
            //                         '<div id="siteNotice">' +
            //                         "</div>" +
            //                         '<h5 id="firstHeading" class="firstHeading">Details</h5>' +
            //                         '<div id="bodyContent">' +
            //                         `<p id="infoSpan">${data.address}</p>` +
            //                         "</div>" +
            //                         '<div id="bodyContent">' +
            //                         `<p id="infoSpan">${info}</p>` +
            //                         "</div>" +
            //                         "</div>");
            //                     infowindow.open(map, marker);
            //                 });
            //             }, 1000)
            //             google.maps.event.addListener(infowindow, 'closeclick', () => {
            //                 // handlePopupAndDropdownClose()
            //             })
            //             oms.addMarker(marker);
            //         }
            //     })(data, marker));

            // }
        }



        {
            dummyData.tripStatus !== 'completed' ?
            axios.post(`${url}/track/getLocation`, { vehicleNumber: vehNumber })
                .then(response => {
                    let vehLat = response && response.data && response.data.data && response.data.data.latitude ? response.data.data.latitude : ''
                    let vehLng = response && response.data && response.data.data && response.data.data.longitude ? response.data.data.longitude : ''
                    marker = new google.maps.Marker({
                        position: new google.maps.LatLng(vehLat, vehLng),
                        map: map,
                        icon: {
                            url: truckNew,
                            scaledSize: new google.maps.Size(65, 65),
                            anchor: new google.maps.Point(0, 30)
                        }
                    });
                    setTimeout(() => {
                        marker.setMap(null);
                    }, 29400)

                })
                .catch(error => {
                    console.log('error', error);
                    if (error.error.status === 401) {
                        logoutOnExpiry()
                    }
                })
            : ''
        }
        {
            dummyData.tripStatus !== 'completed' ?
            setInterval(() => {
                axios.post(`${url}/track/getLocation`, { vehicleNumber: vehNumber })
                    .then(response => {
                        let vehLat = response && response.data && response.data.data && response.data.data.latitude ? response.data.data.latitude : ''
                        let vehLng = response && response.data && response.data.data && response.data.data.longitude ? response.data.data.longitude : ''
                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(vehLat, vehLng),
                            map: map,
                            icon: {
                                url: truckNew,
                                scaledSize: new google.maps.Size(65, 65),
                                anchor: new google.maps.Point(0, 30)
                            }
                        });
                    })
                    .catch(error => {
                        console.log('error', error);
                        if (error.error.status === 401) {
                            logoutOnExpiry()
                        }
                    })
            }, 30000)
            : ''
        }

    }, [])



    return (
        <>
            <div className="contentpanel">
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-5'>
                        <h1 className="title">
                            <button className='button bg-orange mr-2 wd10 flex-inline backBtn' onClick={_moveBck}>
                                <div className='btn_txt font-lg'><i className='fa fa-arrow-left'></i></div>
                            </button>
                            Trip Map
                        </h1>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-container pad-0">
                    <div className='mapHeader'>
                        <div className="cust-row flex-algn-cent">
                            <div className='mapHeader' style={{ justifyContent: 'space-between' }}>
                                <div className='searcBox'>
                                </div>
                                <div className='wd60'>
                                    <button className='myMapBtnGrey' onClick={() => toggleView()}>List View </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='tripDetailsbox'>
                        <span style={{ color: '#fff', fontWeight: '500' }}>Trip ID  - </span>
                        <span style={{ color: '#fff', fontWeight: '500', paddingLeft: '6px' }}>{dummyData && dummyData.tripId ? dummyData.tripId : '-'}</span>
                    </div>
                    {/* {dummyData?.tripStatus !==  "completed" ?
                    <button className='myMapBtn' style={{ width: 'auto', height: '28px', marginLeft: '20px' }} onClick={() => refreshEta()}>Get Latest ETA </button>
                    : null} */}
                    <div className='mapOuter'>
                        <div className='positionsElem slide-out'>
                            <div className='postHeader postFixed'>
                                <div className='wd90' style={{ display: 'flex', justifyContent: 'center', width: '95%' }}>
                                    <i className="fa fa-truck truckFa"></i>
                                    <span className='postDetails' style={{ paddingLeft: '26px' }}>{moment(dummyData.dateOfTrip).format("DD-MM-YYYY")}
                                        &nbsp;TOTAL ETA - ( {calculateETASEC(dummyData.eta)} )
                                    </span>
                                </div>
                                <div className='wd10' style={{ width: '5%' }} onClick={closeSideBar}>
                                    {/* <i className="fa fa-angle-left leftOpenArr" ></i> */}
                                    <i className="fa fa-times-circle leftOpenArr"></i>
                                </div>
                            </div>
                            <div className='overflowDiv' style={{ position: 'relative', top: '13px', marginBottom: '20px' }}>
                                {timeSlots && timeSlots.locations && timeSlots.locations.length !== 0 ?
                                    timeSlots.locations.map((item, index) => {
                                        return (
                                          <div className="alignBdr">
                                            <div key={index} className="postBody">
                                              {index !== 0 ? (
                                                <div className="arroWElem">
                                                  <span className="newetaTime">
                                                    {!item.isDelivered && <div>ETA - {item && item.locationDetails && item.locationDetails.eta ? item.locationDetails.eta : "0 Minutes"}</div>}
                                                  </span>
                                                  <i className="fa fa-arrow-down downArrow"></i>
                                                  <span className="etaTime">
                                                    {item.isDelivered ? (
                                                      <div className="myDeliveredBox">Delivered At - {moment(item?.deliveryTime).format("DD MMM YYYY hh:mm A")}</div>
                                                    ) : (
                                                      <div className='ptaTime'>
                                                        {timeSlots.locations.length - 1 === index
                                                          ? `Pit Stop Time - ${dummyData?.pitStopTime ? calculateETASEC(dummyData.pitStopTime) : "0 Minutes"}`
                                                          : `Service Time - ${
                                                              item && item.locationDetails && item.locationDetails.serviceTime ? calculateETASEC(item.locationDetails.serviceTime) : "0 Minutes"
                                                            }`}
                                                      </div>
                                                    )}
                                                  </span>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <div className={dummyData && timeSlots.currentWaypoint === item.waypoint_index ? "routesElementActive" : "routesElement"}>
                                                {"locationDetails" in item ? (
                                                  item && item.locationDetails ? (
                                                    <span className="ml-2 mr-2 w-100 d-flex justify-content-start">
                                                      <span style={{ width: "5%", height: "inherit" }} className="mr-2 d-flex justify-content-center align-items-center">
                                                        {(item?.locationType === "source" || item?.locationType === "destination") ? "S.":  `${index}.`}
                                                      </span>
                                                      <span style={{ width: "95%" }} className="w-100 d-flex justify-content-center">
                                                        {item.locationDetails.address}
                                                      </span>
                                                    </span>
                                                  ) : (
                                                    ""
                                                  )
                                                ) : (
                                                  "start point"
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                    })
                                    : ''}

                            </div>
                        </div>
                        <div id="myMap" style={{ height: '100vh' }}>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default TripMap;

const locationAreEqual = (arr1, arr2) => {
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false
    if (arr1?.length !== arr2?.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  }


const calculateETASEC = (eta) => {
    if (eta) {
      const hours = Math.floor(eta / 3600);
      const minutes = Math.floor((eta % 3600) / 60);
      const seconds = Math.floor(eta % 60);
  
      let finalTime = "";
  
      if (hours > 0) {
        finalTime += `${hours} h `;
      }
      if (minutes > 0 || hours > 0) {
        finalTime += `${minutes} m `;
      }
      finalTime += `${seconds > 0 ? `${seconds}s` : ""}`;
  
      return finalTime.trim();
    } else {
      return "0 seconds";
    }
  };

