import React, { useEffect, useState } from "react";
import { getVehiclesList } from "../../../state/redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import AddVehicle from "./AddVehicle";
import CustomPageLayout from "../../Utils/CustomPageLayout/CustomPageLayout";
import { InputSearchDropDown, CustomTable, showNotification } from "../../Utils/Inputs";
import { handlePostApi } from "../../../services/functions";


const VehicleSettings = () => {
  const dispatch = useDispatch();
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchObj, setSearchObject] = useState({
    page: pageCount,
    limit: pageLimit,
    searchType: "vehicleNo",
    searchValue: "",
  });
  const listing = useSelector((state) => state.getVehicleRed.vehicleListing);
  const loader = useSelector((state) => state.getVehicleRed.loading);
  const count = useSelector((state) => state.getVehicleRed.count);

  const _handleChange = (e) => {
    setSearchObject({ ...searchObj, [e.target.name]: e.target.value });
  };

  const _handleClick = (id) => {
     setShowAddVehicle(true);
  };
  
  const triggerSearch = (action) => {
    if (action === "clear") {
      dispatch(
        getVehiclesList({
          page: 1,
          limit: 10,
          searchType: searchObj.searchType,
          searchValue: "",
        })
      );
    } else {
      setPageCount(1);
      dispatch(
        getVehiclesList({
          page: 1,
          limit: pageLimit,
          searchType: searchObj.searchType,
          searchValue: searchObj.searchValue,
        })
      );
    }
  };

  const handleEdit = (id) => {
    // console.log('edit====>', id)
    setShowAddVehicle(true);
    setSelectedVehicle(id);
  };

  const deleteVehicle = (id) => {
    showNotification({message: "Delete this Vehicle ?", title: "Alert!!", type: "confirm"},  async (result) => {
      if (result.type === "confirm"){
        const response = await handlePostApi("/vehicle/deleteVehicle", {  _id: id });
        if (response.success) {
          showNotification({message: response.message, title: "Success!!", autohide: true, showInput: false});
          dispatch(
            getVehiclesList({
              page: pageCount,
              limit: pageLimit,
              searchType: "",
              searchValue: "",
            })
          );
        }
      }
    })
  };

  const handlePagination = (page, pageLimitt) => {
    if (page) {
      setPageCount(page);
      dispatch(
        getVehiclesList({
          page: page,
          limit: pageLimit,
          searchType: "",
          searchValue: "",
        })
      );
    }
    if (pageLimitt) {
      setPageCount(1);
      setPageLimit(pageLimitt);
      dispatch(
        getVehiclesList({
          page: 1,
          limit: pageLimitt,
          searchType: "",
          searchValue: "",
        })
      );
    }
    // console.log("handlePagination", page, pageLimit);
  };

  const tableHeader = [
    "S. No",
    "Vehicle Number",
    "Vehicle Type",
    "Quantity",
    <span key="volume">
      Vehicle Volume (cm<sup>3</sup>)
    </span>,
    "Freight Rate",
    "Action",
  ];
  const tableRows =
    (listing &&
      listing.map((item, index) => {
        let items = [];
        items.push(
          <>{(pageCount - 1) * pageLimit + index + 1}</>,
          <>{item.vehicleNo ? item.vehicleNo : "-"}</>,
          <>{item.vehicleType ? item.vehicleType : "-"}</>,
          <>{item.capacityInNumber !== null ? item.capacityInNumber : "-"}</>,
          <>{item.capacityInKg !== null ? item.capacityInKg : "-"}</>,
          <>{item.freightRate ? item.freightRate : "-"}</>,
          <>
            <i className="fa fa-pencil-square-o editIcon" style={{ marginRight: "10px" }} onClick={()=> handleEdit(item._id)}></i>
            <i className="fa fa-trash trashIcon" onClick={() => deleteVehicle(item._id)}></i>
          </>
        );

        return items;
      })) ||
    [];

  useEffect(() => {
    dispatch(
      getVehiclesList({
        page: pageCount,
        limit: pageLimit,
        searchType: "",
        searchValue: "",
      })
    );
  }, [dispatch]);
  
  return (
    <CustomPageLayout
      title="Vehicle Settings"
      addTitleButton="Add Vehicle"
      addHandleClick={() => {
        _handleClick("AddVehicle");
      }}
    >
      <div>
        <div className="searcBox alignCen">
          <InputSearchDropDown
            type="text"
            name="searchValue"
            label="Filter Search"
            value={searchObj.searchValue}
            onChange={(e) => _handleChange(e)}
            placeholder="Search here..."
            style={{ zIndex: "10" }}
            onSubmit={() => triggerSearch("submit")}
            selectOptions={[{ label: "Vehicle Number", value: "vehicleNo" }]}
            selectValue={searchObj.searchType}
            onSelectChange={(item) => {
              setSearchObject({ ...searchObj, searchType: item.target.value, searchValue: "" });
            }}
            onClear={() => {
              setSearchObject({ ...searchObj, searchValue: "" });
              setPageCount(1);
              setPageLimit(10);
              triggerSearch("clear");
            }}
          />
        </div>
      </div>
      <div>
        <CustomTable
          activePage={pageCount}
          pageLimit={pageLimit}
          count={Math.ceil(count / pageLimit)}
          handlePagination={(page, pageLimit) => handlePagination(page, pageLimit)}
          header={tableHeader}
          rows={tableRows ? tableRows : []}
          loading={loader}
        />
      </div>
      <AddVehicle
        show={showAddVehicle}
        onClose={() => {
          setShowAddVehicle(false);
          setSelectedVehicle(null);
        }}
        sharedData={{id : selectedVehicle, pageCount, pageLimit}}
      />
    </CustomPageLayout>
  );
};
export default VehicleSettings;
