import React, { useEffect, useState } from 'react';
import { getAreasList, getCustomerLists } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import LocationPlot from './LocationPlot';
import CustomPageLayout from '../Utils/CustomPageLayout/CustomPageLayout';
import { InputSearchDropDown, InputSearch, Input, CustomTable, showNotification, DropDown } from '../Utils/Inputs';
import { handlePostApi, colourStyles } from '../../services/functions';

const searchTypeOptions = [
  { label: "Name", value: "name" },
  { label: "Mobile", value: "mobile" },
  { label: "Address", value: "address" },
];

const consumerTypeOptions = [
  { label: "All", value: "all" },
  { label: "Available", value: "true" },
  { label: "Not-Available", value: "false" },
];

const CustomerManagement = () => {
    const dispatch = useDispatch()
    const areaLists = useSelector((state) => state.getAreasRed.areaLists)
    const listing = useSelector((state) => state.getCustRed.customerList)
    const loader = useSelector((state) => state.getCustRed.loading)
    const message = useSelector((state) => state.getCustRed.message)
    const count = useSelector((state) => state.getCustRed.count)
    const [geo, setGeo] = useState({ lat: '', lng: '' });
    const [showLocation, setShowLocation] = useState(false)

    const [areaOptions, setAreaOptions] = useState([])
    const [pageLimit, setPageLimit] = useState(10)
    const [pageCount, setPageCount] = useState(1)
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        areaId: '',
        searchType: 'consumerName',
        searchValue: '',
        location: '',
    })

    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = (action, payload) => {
        if (action === "clear") {
          dispatch(
            getCustomerLists({
              page: 1,
              limit: 10,
              areaId: searchObj.areaId,
              location: searchObj.location,
              searchType: "",
              searchValue: "",
            })
          );
        } else {
            if (payload) {
              dispatch(getCustomerLists({ ...payload }));
            } else {
              setPageCount(1);
              dispatch(
                getCustomerLists({
                  page: 1,
                  limit: pageLimit,
                  areaId: searchObj.areaId,
                  location: searchObj.location,
                  searchType: searchObj.searchType,
                  searchValue: searchObj.searchValue,
                })
              );
            }
          
        }
    }

    useEffect(()=> {
        if(areaLists?.length > 0){
            setAreaOptions(areaLists.map((item) => ({
                value: item.areaId, 
                label: item.areaDescription
            })))
        }
    },[areaLists])

    const handlePagination = (page, pageLimitt) => {
        if (page) {
          setPageCount(page);
          dispatch(
            getCustomerLists({
              ...searchObj,
              page: page,
              limit: pageLimit,
            })
          );
        }
        if (pageLimitt) {
          setPageCount(1);
          setPageLimit(pageLimitt);
          dispatch(
            getCustomerLists({
              ...searchObj,
              page: 1,
              limit: pageLimitt,
            })
          );
        }
        // console.log("handlePagination", page, pageLimit);
      };

    useEffect(() => {
        dispatch(getAreasList());
    }, [dispatch]);

    const tableHeader = ["S. No", "Name", "Consumer Id", "Address", "Area Description", "Mobile Number", "Latest Location", "Area Code & Area ID"]
    const tableRows =
    (searchObj.areaId && listing &&
      listing.map((item, index) => {
        let items = [];
        items.push(
          <>{(pageCount - 1) * pageLimit + index + 1}</>,
          <>{item && item.name ? item.name : "-"}</>,
          <>{item && item.consumerId ? item.consumerId : '-'}</>,
          <>{item && item.address ? item.address : "-"}</>,
          <>{item && item.areaDescription ? item.areaDescription : "-"}</>,
          <>{item && item.mobile ? item.mobile : "-"}</>,
          <span
            style={{ textDecoration: "underline", textUnderlineOffset: "4px", color: "#0079c9", cursor: "pointer" }}
            onClick={() => {
              setGeo({ lat: item.latestLocation.latitude, lng: item.latestLocation.longitude });
              setShowLocation(true);
            }}
          >
            {"latestLocation" in item ? (item && item.latestLocation ? item.latestLocation.latitude.toFixed(4) : "-") : ""}{" "}
            {"latestLocation" in item ? item && item.latestLocation ? "," + item.latestLocation.longitude.toFixed(4) : "-" : <span style={{ color: "#fff" }}>-</span>}
          </span>,

          <>
            {item && item.areaCode ? item.areaCode : "-"} , {item && item.areaId ? item.areaId : "-"}
          </>
        );

        return items;
      })) ||
    [];

    return (
      <CustomPageLayout title="Consumer Management">
        <div className="d-flex" style={{ flexWrap: "wrap" }}>
          <div className="" style={{ width: "200px" }}>
            <DropDown
              onChange={(value) => {
                setSearchObject((prev) => ({ ...prev, areaId: value.value }));
                setPageCount(1);
                setPageLimit(10);
                triggerSearch("submit", { ...searchObj, areaId: value.value });
              }}
              label="Select Area"
              options={areaOptions}
              styles={colourStyles}
              height={"40px"}
              value={areaOptions.find((el) => el.value === searchObj.areaId)}
            />
          </div>
          <div className="searcBox alignCen">
            <InputSearchDropDown
              type="text"
              label="Filter Search"
              name="searchValue"
              value={searchObj.searchValue}
              onChange={(e) => _handleChange(e)}
              placeholder="Search here..."
              style={{ zIndex: "10" }}
              onSubmit={() => triggerSearch("submit")}
              selectOptions={searchTypeOptions}
              selectValue={searchObj.searchType}
              onSelectChange={(item) => {
                setSearchObject({ ...searchObj, searchType: item.target.value, searchValue: "" });
              }}
              onClear={() => {
                setSearchObject({ ...searchObj, searchValue: "" });
                setPageCount(1);
                setPageLimit(10);
                triggerSearch("clear");
              }}
            />
          </div>
          <div className="" style={{ width: "200px", marginLeft: "5px" }}>
            <DropDown
              onChange={(value) => {
                setSearchObject((prev) => ({ ...prev, location: value.value }));
                triggerSearch("submit", { ...searchObj, location: value.value });
              }}
              label="Select Location"
              options={consumerTypeOptions}
              styles={colourStyles}
              height={"40px"}
              value={consumerTypeOptions.find((el) => el.value === searchObj.location)}
            />
          </div>
        </div>
        <div>
          <CustomTable
            activePage={!searchObj.areaId ? 1 : pageCount}
            pageLimit={!searchObj.areaId ? 10 : pageLimit}
            count={!searchObj.areaId ? 0 : Math.ceil(count / pageLimit)}
            handlePagination={(page, pageLimit) => handlePagination(page, pageLimit)}
            header={tableHeader}
            rows={tableRows ? tableRows : []}
            loading={loader}
            customErrorMessage={!searchObj.areaId || listing && listing?.length === 0 ? "Select Area to view the list" : "No Data Found"}
          />
        </div>
        <LocationPlot
          show={showLocation}
          onClose={() => {
            setShowLocation(false);
            setGeo({lat: '', lng: ''});
          }}
          sharedData={{ geo: geo }}
        />
      </CustomPageLayout>
    );
}
export default CustomerManagement;