import React, { useEffect, useState } from 'react';
import { getSettings } from '../../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import { colourStyles,handlePostApi } from '../../../services/functions';
import { DropDown, showNotification } from '../../Utils/Inputs';
import CustomPageLayout from '../../Utils/CustomPageLayout/CustomPageLayout';
import { Container, Row, Col } from "react-bootstrap";

const ProductSettings = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getSetRed.settingslisting);
    const [productSettings, setProductSettings] = useState(
        {
            unitType: localStorage.getItem('unit') || ""
        }
    )

    const settingsTypes = [
        { label: 'Quantity (Number)', value: 'Number' },
        { label: 'Kg', value: 'Kg' },
    ]

    const handleSettings = (item) => {
        let temp = { ...productSettings }
        temp.unitType = item.value
        setProductSettings(temp)
    }


    const addSettings = async () => {
        try {
            const result = await handlePostApi("/setting/addSetting", { ...productSettings });
            // console.log("api result====>>>>", result);
            if (result.success) {
              localStorage.setItem('unit', productSettings.unitType)
              showNotification({message: result.message, title: "Success!!", autohide: true, showInput: false});
              dispatch(getSettings())
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    }
    useEffect(()=>{
      if(listing?.length > 0 && listing[0]?.unitType){
        let temp = {...productSettings}
        const unit = listing?.unitType || localStorage.getItem('unit'); 
        temp.unitType = unit
        setProductSettings(temp)
      }
    },[listing])

    useEffect(() => {
        dispatch(getSettings())
    }, []) 
    return (
      <CustomPageLayout title={"Product Settings"}>
        <Container fluid className="">
          <Row className="addBg p-2 mt-2">
            <Col>
              <Row className="px-2 d-flex justify-content-start align-items-center">
                <span className="custSpan" style={{fontSize : "12px"}}>Please Select Unit : &nbsp; </span>
                <div style={{ width: "200px" }}>
                  <DropDown onChange={(value) => handleSettings(value)} options={settingsTypes} styles={colourStyles} value={productSettings.unitType != "" ? settingsTypes.filter((el)=> el.value === productSettings.unitType)[0] : null} />
                </div>
                <button className="productSave" onClick={addSettings}>
                  Save
                </button>
              </Row>
            </Col>
          </Row>
        </Container>
      </CustomPageLayout>
    );
}

export default ProductSettings;