import React, { useEffect, useState } from 'react';
import AddAddress from './AddAddress';
import AddressRoute from '../Dashboard/Modal/AddressRoute';
import Geofence from './Geofence';
import { useDispatch, useSelector } from 'react-redux';
import { getAddress } from '../../state/redux/actions/index';
import CustomPageLayout from '../Utils/CustomPageLayout/CustomPageLayout';
import { InputSearchDropDown, CustomTable, showNotification } from "../Utils/Inputs";
import { handlePostApi } from "../../services/functions";

const optionsArray = [
    { label: "Address", value: "address" },
    { label: "Company Name", value: "company" },
    { label: "Mobile Number", value: "contactDetails.mobileNo" },
    { label: "Contact Person Name", value: "contactDetails.personName" }
];

const AddressManag = () => {
    const dispatch = useDispatch();
    const listing = useSelector((state) => state.addressListRed.addressListing);
    const loader = useSelector((state) => state.addressListRed.loading);
    const message = useSelector((state) => state.addressListRed.message);
    const count = useSelector((state) => state.addressListRed.count);

    const [pageCount, setPageCount] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [searchObj, setSearchObject] = useState({
      page: pageCount,
      limit: pageLimit,
      searchType: "",
      searchValue: "",
    });
    const [showAddAddress, setShowAddAddress] = useState(false);
    const [showGeofence, setShowGeofence] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [geo, setGeo] = useState({});

    // console.log('loader', loader)

    

    // const toggleModalVisibility = (id, visible) => {
    //     const action = visible ? 'add' : 'remove';
    //     document.querySelector(`.custom-modal-overlay`).classList[action]("custom-modal-is-visible");
    //     document.querySelector(`#${id}`).classList[action]("custom-modal-is-visible");
    // };

    // const shareData = (item, index) => setGeo({ lat: listing[index].latitude, lng: listing[index].longitude, geo: item });
    // const shareToModal = (item) => setInfo({ data: item, update: true, pagination: { pageNumber: pageCount, entries: pageLimit } });
    // const callBack = () => setInfo({ data: '', update: false, pagination: { entries: '', pageNumber: '' } });

    // const _searchDrop = (id, value) => setSearchObject(prev => ({ ...prev, searchType: id }));
    // const _handleChange = (e) => setSearchObject(prev => ({ ...prev, [e.target.name]: e.target.value }));
    
    // const triggerSearch = () => {
    //     dispatch(getAddress({ page: '', limit: pageLimit, searchType: searchObj.searchType, searchValue: searchObj.searchValue }));
    // };

    // const changePage = (increment) => setPageCount(prev => Math.max(1, prev + increment));

    // const handleChange = (item) => {
    //     setPageLimit(item.value);
    //     setPageCount(1);
    //     dispatch(getAddress({ page: 1, limit: item.value, searchType: '', searchValue: '' }));
    // };

    const _handleChange = (e) => {
      setSearchObject({ ...searchObj, [e.target.name]: e.target.value });
    };

    const triggerSearch = (action) => {
      if (action === "clear") {
        dispatch(
            getAddress({
            page: 1,
            limit: 10,
            searchType: searchObj.searchType,
            searchValue: "",
          })
        );
      } else {
        setPageCount(1);
        dispatch(
            getAddress({
            page: 1,
            limit: pageLimit,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue,
          })
        );
      }
    };

    const deleteAddress = (id) => {
        showNotification({message: "Delete this Address ?", title: "Are you Sure..", type: "confirm"},  async (result) => {
          if (result.type === "confirm"){
            const response = await handlePostApi("/route/deleteAddress", {  _id: id });
            if (response.success) {
              showNotification({message: response.message, title: "Success!!", autohide: true, showInput: false});
              dispatch(
                getAddress({
                  page: pageCount,
                  limit: pageLimit,
                  searchType: "",
                  searchValue: "",
                })
              );
            }
          }
        })
      };

    const handlePagination = (page, pageLimitt) => {
        if (page) {
          setPageCount(page);
          dispatch(
            getAddress({
              page: page,
              limit: pageLimit,
              searchType: "",
              searchValue: "",
            })
          );
        }
        if (pageLimitt) {
          setPageCount(1);
          setPageLimit(pageLimitt);
          dispatch(
            getAddress({
              page: 1,
              limit: pageLimitt,
              searchType: "",
              searchValue: "",
            })
          );
        }
        // console.log("handlePagination", page, pageLimit);
      };

    const tableHeader = [
        "S. No",
        "Company Name",
        "Address",
        "Location",
        "Contact Details",
        "Action"
    ];

    const tableRows =
    (listing &&
      listing.map((item, index) => {
        let items = [];
        items.push(
          <>{(pageCount - 1) * pageLimit + index + 1}</>,
          <>{item.company ? item.company : "-"}</>,
          <>{item.address ? item.address : "-"}</>,
          <span
            style={{ textDecoration: "underline", textUnderlineOffset: "4px", color: "#0079c9", cursor: "pointer" }}
            onClick={() => {
                setShowGeofence(true)
                setGeo({ lat: item.latitude, lng: item.longitude, geofence: item.geofence, geofenceId : item.geofenceId });
            }}
          >
            {item.latitude} , {item.longitude}
          </span>,
          <>
            {item.contactDetails && item.contactDetails.personName ? item.contactDetails.personName : "-"} , {item.contactDetails && item.contactDetails.mobileNo ? item.contactDetails.mobileNo : "-"}{" "}
            , {item.contactDetails && item.contactDetails.email ? item.contactDetails.email : "-"}
          </>,
          <>
            <p></p>
            <>
              <i
                className="fa fa-pencil-square-o editIcon"
                onClick={() => {
                    setShowAddAddress(true);
                    setSelectedAddress(item._id);
                }}
                style={{ marginRight: "10px" }}
              ></i>
              <i className="fa fa-trash trashIcon" 
              onClick={() => deleteAddress(item._id)}
              ></i>
            </>
          </>
        );

        return items;
      })) ||
    [];

    useEffect(() => {
      dispatch(getAddress({ page: pageCount, limit: pageLimit, searchType: "", searchValue: "" }));
    }, [dispatch]);

    return (
      <CustomPageLayout
        title="Address Management"
        addTitleButton="Add Address"
        addHandleClick={() => {
            setShowAddAddress(true);
            setSelectedAddress(null);
        }}
      >
        <div>
          <div className="searcBox alignCen">
            <InputSearchDropDown
              type="text"
              name="searchValue"
              label="Filter Search"
              value={searchObj.searchValue}
              onChange={(e) => _handleChange(e)}
              placeholder="Search here..."
              style={{ zIndex: "10" }}
              onSubmit={() => triggerSearch("submit")}
              selectOptions={optionsArray}
              selectValue={searchObj.searchType}
              onSelectChange={(item) => {
                setSearchObject({ ...searchObj, searchType: item.target.value, searchValue: "" });
              }}
              onClear={() => {
                setSearchObject({ ...searchObj, searchValue: "" });
                setPageCount(1);
                setPageLimit(10);
                triggerSearch("clear");
              }}
            />
          </div>
        </div>
        <div>
          <CustomTable
            activePage={pageCount}
            pageLimit={pageLimit}
            count={Math.ceil(count / pageLimit)}
            handlePagination={(page, pageLimit) => handlePagination(page, pageLimit)}
            header={tableHeader}
            rows={tableRows ? tableRows : []}
            loading={loader}
          />
        </div>
        <AddAddress
          show={showAddAddress}
          onClose={() => {
            setShowAddAddress(false);
            setSelectedAddress(null);
          }}
          sharedData={{ id: selectedAddress, pageCount, pageLimit }}
        />
        <Geofence
          show={showGeofence}
          onClose={() => {
            setShowGeofence(false);
            setGeo({ lat: "", lng: "", geofence: {}, geofenceId: "" });
          }}
          sharedData={{ ...geo }}
        />
      </CustomPageLayout>
    );
};

export default AddressManag;