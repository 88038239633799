/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logoutOnExpiry, url } from '../../services/requests'
import { colourStyles } from '../../services/functions';
import Select from 'react-select';
import { Table } from "react-bootstrap";
import axios from 'axios';
import moment from 'moment';
import { getDistributorActivityData } from '../../state/redux/actions/distributorActivity';
import { getDistributorAdminList } from '../../state/redux/actions';

const entries = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 30, value: 30 },
    { label: 40, value: 40 },
    { label: 50, value: 50 }
]

const Analytics = () => {
    const dispatch = useDispatch();
    const listing = useSelector((state) => state?.getDistributorActivityRed?.distributorActivityListing) || [];
    const loader = useSelector((state) => state?.getDistributorActivityRed?.loading) || false
    const message = useSelector((state) => state?.getDistributorActivityRed?.message) || ""
    const count = useSelector((state) => state?.distributorListingReducer?.count) || 10;
    //
    const distributorListing = useSelector((state) => state?.distributorListingReducer?.distributorListing).map((ele) => ({ 'value': ele._id, 'label': ele.name })) || [];
    // console.log('feedbackRatingOption', useSelector((state) => state?.distributorListingReducer?.distributorListing));
    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const [filterDate, setFilterDate] = useState({
        startDate: new Date().toISOString().split('T')[0],
        endDate:  new Date().toISOString().split('T')[0]
    })
    const [selectedDistributor, setSelectedDistributor] = useState([]);

    const nextPage = () => {
        setPageNo(function (pageNo) {
            return (pageNo += 1);
        });
    }
    const prevPage = () => {
        setPageNo(function (pageNo) {
            return (pageNo -= 1);
        });
    };
    const _handleDateChange = (e, name) => {
        e.preventDefault();
        // console.log(e.target.value);
        if (name == 'start') {
            const newStartDate = e.target.value;
            setFilterDate((prevState) => {
                // If start date is not less than end date, clear the start date
                if (prevState.endDate && new Date(newStartDate) > new Date(prevState.endDate)) {
                    return { ...prevState, startDate: '' };
                } else {
                    return { ...prevState, startDate: newStartDate };
                }
            });
        }
        else {
            const newEndDate = e.target.value;
            setFilterDate((prevState) => {
                // If the start date exists and is not less than the end date, clear the start date
                if (prevState.startDate && new Date(prevState.startDate) > new Date(newEndDate)) {
                    return { ...prevState, startDate: '', endDate: newEndDate };
                } else {
                    return { ...prevState, endDate: newEndDate };
                }
            });
        }
    }
    const _handleChange = (value, name) => {
        // console.log(value);
        if (name == 'distributor') {
            setSelectedDistributor(value)
        }
        if (name == 'entries') {
            setPageLimit(value.value)
        }
    }

    const search = () => {
        let payload = {
            "from_date": filterDate.startDate,
            "to_date": filterDate.endDate,
            "pageNo": pageNo,
            "limit": pageLimit,
            "distributorIds": selectedDistributor.map((ele) => ele.value)
        }
        // console.log('call search', payload);
        dispatch(getDistributorActivityData(payload))
    }


    useEffect(() => {
        let payload = {
            "from_date": filterDate.startDate,
            "to_date": filterDate.endDate,
            "pageNo": pageNo,
            "limit": pageLimit,
            "distributorIds": selectedDistributor.map((ele) => ele.value)
        }
        // console.log('payload', payload);
        dispatch(getDistributorActivityData(payload))
    }, [pageLimit, pageNo])

    useEffect(() => {
        dispatch(getDistributorAdminList())
    }, [])


    return (
        <>
            <div className="contentpanel" >
                <div className="cust-row flex-algn-cent">
                    <div className='cust-col-5'>
                        <h1 className="title">
                            Analytics
                        </h1>
                    </div>
                    <div className='cust-col-5 flex-jc-end'>
                        <h1 className="title font-bold orange_color">
                        </h1>
                    </div>
                </div>
                <hr className='bgg' />
                <div className="cust-container">
                    <div class="cust-row">
                        <div class="cust-col-10">
                            {/* localStorage.getItem("userType") !== "distributor" */}
                            {true && <div className='bdaOuter'>
                                <div className='searcBox alignCen'>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', margin: '8px' }}>
                                        <div style={{ width: '250px' }}>
                                            <Select
                                                isMulti
                                                value={selectedDistributor}
                                                onChange={(e) => _handleChange(e, 'distributor')}
                                                options={distributorListing}
                                                placeholder="Select Distributor"
                                                styles={colourStyles}
                                            />
                                        </div>
                                        <div className="input-group-append" >
                                            <button type="submit" onClick={() => search()} className="btn btn-orange p-1 pl-3 pr-3 pt-2">
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', margin: '8px' }}>
                                        <div className='firstCalenBox' style={{ marginRight: '3px' }}>
                                            <span className='calenSpan'>Start Date</span>
                                            <input data-testid='start-date-input' className='twodateInput' max={filterDate?.endDate || new Date().toISOString().split('T')[0]} type='date' value={filterDate.startDate} onChange={(e) => _handleDateChange(e, 'start')} />
                                        </div>
                                        <div className='firstCalenBox'>
                                            <span className='calenSpan'>End Date</span>
                                            <input data-testid='end-date-input' className='twodateInput' max={new Date().toISOString().split('T')[0]} type='date' value={filterDate.endDate} onChange={(e) => _handleDateChange(e, 'end')} />
                                        </div>

                                        <div className="input-group-append" >
                                            <button data-testid='search-button' type="submit" onClick={() => search()} className="btn btn-orange p-1 pl-3 pr-3 pt-2" disabled={filterDate.startDate === '' || filterDate.endDate === '' ? true : false}>
                                                <i className="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {loader === false ?
                                listing && listing?.length !== 0 ?
                                    <div className="generic-table-wrapper mt20">
                                        <Table className="generic-table">
                                            <thead className="bg-accent">
                                                <tr>
                                                    <th>S No.</th>
                                                    <th>Distributor Name</th>
                                                    <th>No of orders</th>
                                                    <th>No of deliveries</th>
                                                    <th>No of locations captured</th>
                                                    <th>No of trips</th>
                                                    <th>Cash On Delivery (COD) orders count</th>
                                                    <th>Prepaid orders count</th>
                                                    <th>No of drivers</th>
                                                    <th>Orders delivered with OTP</th>
                                                    <th>Orders delivered without OTP</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-gray-100">
                                                {listing && listing?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td style={{ color: '#007BC9' }}>
                                                                {((pageNo - 1) * (pageLimit)) + index + 1}
                                                            </td>
                                                            <td>{item._id ? item._id?.distributorName : '-'}</td>
                                                            <td>{item.requestCount ? item.requestCount : '-'}</td>
                                                            <td>{item.deliveryCount ? item.deliveryCount : '-'}</td>

                                                            <td>{item.locationCount ? item.locationCount : '-'}</td>
                                                            <td>{item.tripCount ? item.tripCount : '-'}</td>
                                                            <td>{item.codPayment_delivery ? item.codPayment_delivery : '-'}</td>
                                                            <td>{item.prepaidPayment_delivery ? item.prepaidPayment_delivery : '-'}</td>
                                                            <td>{item.driverCount ? item.driverCount : '-'}</td>
                                                            <td>
                                                                {item.withOTP_delivery ? item.withOTP_delivery : "-"}
                                                            </td>
                                                            <td>
                                                                {item.withoutOTP_delivery ? item.withoutOTP_delivery : "-"}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                    :
                                    <div className='noData'>
                                        <p>{message}</p>
                                    </div>
                                :
                                <div className={loader === true ? 'loaderDiv' : 'loaderDivNone'}>
                                    <i className="fa fa-spinner fa-spin loadingSpinnercolor font-xxl "></i>
                                </div>

                            }
                            {listing && listing.length !== 0 ?
                                <div className='paginationDiv'>
                                    <div className='entries'>
                                        <span className='entrieSpan'>Show Entries :</span>
                                        <Select
                                            value={entries.filter((ele) => ele.value === pageLimit)[0]}
                                            onChange={(value) => _handleChange(value, 'entries')}
                                            options={entries}
                                            styles={colourStyles}
                                        />
                                    </div>
                                    <button data-testid='previous-page-button' disabled={pageNo === 1 ? true : false} className='paginationBtn' onClick={prevPage}>Previous</button>
                                    <button data-testid='next-page-button' disabled={Math.floor((count + pageLimit - 1) / pageLimit) === pageNo ? true : false} className='paginationBtn' onClick={nextPage}>Next</button>
                                </div>
                                : ''}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Analytics;


// const driverDetail = async () => {
//     const token = localStorage.getItem('secretkey');
//     const config = {
//         headers: { Authorization: token }
//     };

//     try {
//         const response = await axios.post(`${url}/track/getDriverLocation`, {}, config);
//         const { success, message, data } = response.data;

//         if (success) {
//             return data;
//         } else {
//             console.log('error', message);
//             return [];
//         }
//     } catch (error) {
//         console.log('error', error);
//         return [];
//     }
// };
