import React, { useEffect, useState } from 'react';
import { getRequestList } from '../../state/redux/actions/index';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import AddRequest from './AddRequest';
import CustomPageLayout from '../Utils/CustomPageLayout/CustomPageLayout';
import { InputSearch, InputSearchDropDown, CustomTable, showNotification, DropDown } from '../Utils/Inputs';
import { handlePostApi, colourStyles } from '../../services/functions';


const statusOtions = [
  { label: "All", value: "All" },
  { label: "Offline Delivered", value: "order Delivered Offline" },
  { label: "Ready", value: "ready" },
  { label: "Trip Created", value: "trip Created" },
  { label: "Outside Region", value: "outsideRegion" },
];

const Request = () => {
    const dispatch = useDispatch()
    const listing = useSelector((state) => state.getRequestRed.requestListing)
    const loader = useSelector((state) => state.getRequestRed.loading)
    const count = useSelector((state) => state.getRequestRed.count)
    const [pageCount, setPageCount] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [searchObj, setSearchObject] = useState({
        page: pageCount,
        limit: pageLimit,
        searchType: 'requestId',
        searchValue: '',
        requestDate: "",
        orderStatus: "All"
    })
    const [showAddRequest, setShowAddRequest] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);

    const _handleClick = () => {
        setShowAddRequest(true);
        setSelectedRequest(null);
    }
    
    const _handleChange = (e) => {
        setSearchObject({ ...searchObj, [e.target.name]: e.target.value })
    }
    const triggerSearch = (action, payload) => {
      // console.log("search==>", searchObj);
      if (action === "clear") {
        setSearchObject({
          page: 1,
          limit: 10,
          searchType: 'requestId',
          searchValue: "",
          requestDate: "",
          orderStatus: "All"
        });
        dispatch(
          getRequestList({
            page: 1,
            limit: 10,
            searchType: 'requestId',
            searchValue: "",
            orderStatus: "All"
          })
        );
      } else {
        setPageCount(1);
        if (payload) {
          dispatch(getRequestList({ ...payload, page: 1, limit: pageLimit }));
        } else {
          dispatch(
            getRequestList({
              page: 1,
              limit: pageLimit,
              searchType: searchObj.searchType,
              searchValue: searchObj.searchValue,
              requestDate: searchObj.requestDate,
              orderStatus: searchObj.orderStatus,
            })
          );
        }
        
      }
    };

    const handleStatusSubmit = (request) => {
      // console.log(request);
      let payload = {
        _id: request._id,
        updateDetails: {
          orderStatusToUpdate: "offline Delivered",
        },
      };
      showNotification({ message: "Update this Request ?", title: "Alert!!", type: "confirm" }, async (result) => {
        if (result.type === "confirm") {
          const response = await handlePostApi("/request/editRequest", { ...payload });
          if (response.success) {
            showNotification({ message: response.message, title: "Success!!", autohide: true, showInput: false });
            dispatch(
              getRequestList({
                page: pageCount,
                limit: pageLimit,
                searchType: "",
                searchValue: "",
              })
            );
          }
        }
      });
    };

    const handlePagination = (page, pageLimitt) => {
      if (page) {
        setPageCount(page);
        dispatch(
          getRequestList({
            page: page,
            limit: pageLimit,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue,
            requestDate: searchObj.requestDate,
            orderStatus: searchObj.orderStatus,
          })
        );
      }
      if (pageLimitt) {
        setPageCount(1);
        setPageLimit(pageLimitt);
        dispatch(
          getRequestList({
            page: 1,
            limit: pageLimitt,
            searchType: searchObj.searchType,
            searchValue: searchObj.searchValue,
            requestDate: searchObj.requestDate,
            orderStatus: searchObj.orderStatus,
          })
        );
      }
      // console.log("handlePagination", page, pageLimit);
    };

    const tableHeader = ["S. No", "Request ID", "Contact Details", "Area Id", "Address", "Quantity", "Book Date", "Create Date", "Update Date", "Time Slot", "Status", "Action"];
    const tableRows =
      (listing &&
        listing?.length > 0 &&
        listing.map((item, index) => {
          let items = [];
          items.push(
            <>{(pageCount - 1) * pageLimit + index + 1}</>,
            <>{item.requestId ? item.requestId : "-"}</>,
            <>
              {item.contactDetail && item.contactDetail.personName ? item.contactDetail.personName : ""} , {item.contactDetail && item.contactDetail.mobileNo ? item.contactDetail.mobileNo : ""} ,{" "}
              {item.contactDetail && item.contactDetail.email ? item.contactDetail.email : ""}
            </>,
            <>{item?.productDetails?.AreaId ? item.productDetails.AreaId : "-"}</>,
            <>{item.address ? item.address.address : "-"}</>,

            <>{item.quantity ? item.quantity : "-"}</>,
            <>{item.date ? moment(item.date).format("DD-MM-YYYY") : "-"}</>,
            <>{item.createdAt ? `${formattedDateTime(item.createdAt)}` : "-"}</>,
            <>{item.updatedAt ? `${formattedDateTime(item.updatedAt)}` : "-"}</>,
            <>
              {item.startSlotTime}:00 - {item.endSlotTime}:00
            </>,
            <>
              <p style={{ marginTop : '15px', color: item?.orderStatus === "ready" || item?.orderStatus === "order Delivered Offline" ? "green" : "blue" }}>
                {item?.orderStatus === "ready" ? "Ready" : item?.orderStatus === "order Delivered Offline" ? "Delivered Offline" : item?.orderStatus === "outsideRegion" ? "Outside Region" : "Trip Created"}
              </p>
            </>,
            <>
              {item?.orderStatus === "ready" ? (
                <button style={{ height: "auto" }} className="myMapBtnGrey" onClick={() => handleStatusSubmit(item)}>
                  Update Status
                </button>
              ) : (
                "-"
              )}
            </>
          );

          return items;
        })) ||
      [];


    const getRowStyle = (index) => {
      if (listing?.length > 0) {
        return listing[index].orderStatus === 'outsideRegion' ? { backgroundColor: "#F9A5A552" } : {backgroundColor: "#ffffff"};
      } else {
        return { backgroundColor: "#ffffff" };
      }
    }

    useEffect(() => {
      dispatch(
        getRequestList({
          page: pageCount,
          limit: pageLimit,
          searchType: "",
          searchValue: "",
          orderStatus: searchObj.orderStatus,
        })
      );
    }, [dispatch]);

    return (
      <CustomPageLayout
        title="Request"
        addTitleButton="Add Request"
        addHandleClick={() => {
          _handleClick();
        }}
      >
        <div className="d-flex flex-wrap">
          <div className="searcBox alignCen">
            <InputSearchDropDown
              type="text"
              name="searchValue"
              label="Filter Search"
              value={searchObj.searchValue}
              onChange={(e) => _handleChange(e)}
              placeholder="Search here..."
              style={{ zIndex: "10" }}
              onSubmit={() => triggerSearch("submit")}
              selectOptions={[
                { label: "Request Id", value: "requestId" },
                { label: "Customer Name", value: "contactDetail.personName" },
              ]}
              selectValue={searchObj.searchType}
              onSelectChange={(item) => {
                setSearchObject({ ...searchObj, searchType: item.target.value, searchValue: "" });
              }}
              onClear={() => {
                setSearchObject({ ...searchObj, searchValue: "" });
                setPageCount(1);
                setPageLimit(10);
                triggerSearch("clear");
              }}
            />
          </div>
          <div className="" style={{ width: "200px", marginLeft: "5px" }}>
            <DropDown
              onChange={(value) => {
                setSearchObject((prev) => ({ ...prev, orderStatus: value.value }));
                triggerSearch("submit", { ...searchObj, orderStatus: value.value });
              }}
              label="Select Status"
              options={statusOtions}
              styles={colourStyles}
              height={"40px"}
              value={statusOtions.find((el) => el.value === searchObj.orderStatus)}
            />
          </div>
          <div className="searcBox alignCen">
            <InputSearch
              type="date"
              name="requestDate"
              label="Booking Date"
              value={searchObj.requestDate}
              onChange={(e) => _handleChange(e)}
              placeholder="Search here..."
              style={{ zIndex: "10" }}
              onSubmit={() => triggerSearch("submit")}
            />
          </div>
        </div>
        <div>
          <CustomTable
            activePage={pageCount}
            pageLimit={pageLimit}
            count={Math.ceil(count / pageLimit)}
            handlePagination={(page, pageLimit) => handlePagination(page, pageLimit)}
            header={tableHeader}
            rows={tableRows ? tableRows : []}
            rowStyleCondition={getRowStyle}
            loading={loader}
          />
        </div>
        <AddRequest
          show={showAddRequest}
          onClose={() => {
            setShowAddRequest(false);
            setSelectedRequest(null);
          }}
          sharedData={{ id: selectedRequest, pageCount, pageLimit }}
        />
      </CustomPageLayout>
    );
}
export default Request;

export const formattedDateTime = (date) => {
  return moment(date)
  .format('DD-MM-YYYY hh:mm:ss A');
}