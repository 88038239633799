import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'
import { getUsers, getAreasList } from '../../../state/redux/actions/index';
import { getVehiclesList } from '../../../state/redux/actions/index';
import { colourStyles, valid_email, valid_mobile } from '../../../services/functions';
import { CustomViewModal, Input, DropDown, showNotification } from "../../Utils/Inputs";
import { handlePostApi } from '../../../services/functions'

const defaultDriverPayload = {
  name: "",
  email: "",
  mobile: "",
  status: false,
  userType: "driver",
  maxTripDuration: "",
  earliestTripTime: "",
  latestTripTime: "",
  waitingTime: "",
  driverRestTime: "",
  areaId: [],
  secondaryAreaId: [],
  othersAreaId: [],
  eligibleForVehicle: "",
  time: "",
};

const defaultDriverError = {
  name: "",
  email: "",
  mobile: "",
  status: false,
  userType: "",
  maxTripDuration: "",
  earliestTripTime: "",
  latestTripTime: "",
  waitingTime: "",
  driverRestTime: "",
  areaId: "",
  secondaryAreaId: "",
  othersAreaId: "",
  eligibleForVehicle: "",
  time: "",
};

const timeSlots = [
  { label: "00", value: "0" },
  { label: "01", value: "1" },
  { label: "02", value: "2" },
  { label: "03", value: "3" },
  { label: "04", value: "4" },
  { label: "05", value: "5" },
  { label: "06", value: "6" },
  { label: "07", value: "7" },
  { label: "08", value: "8" },
  { label: "09", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
  { label: "16", value: "16" },
  { label: "17", value: "17" },
  { label: "18", value: "18" },
  { label: "19", value: "19" },
  { label: "20", value: "20" },
  { label: "21", value: "21" },
  { label: "22", value: "22" },
  { label: "23", value: "23" },
  { label: "24", value: "24" },
];

const AddDriver = ({ show, onClose, sharedData }) => {
    const dispatch = useDispatch();
    const listing = useSelector((state) => state.getAreasRed.areaLists);
    const driverlisting = useSelector((state) => state.getUserRed.usersList)
    const vehicleListing = useSelector((state) => state.getVehicleRed.vehicleListing);
    const vehicleloader = useSelector((state) => state.getVehicleRed.loading);
    const vehicleCount = useSelector((state) => state.getVehicleRed.count);


    const [driverPayload, setDriverPayload] = useState(defaultDriverPayload);
    const [error, setError] = useState(defaultDriverError);

    const [areaListing, setAreaListing] = useState([])

    const [updateObj, setUpdateObj] = useState({})
    const [selectedVehicle, setSelectedVehicle] = useState([]);
    const [vehicleOption, setVehicleOption] = useState([]);
    const [isVehicleUpdated, setIsVehicleUpdated] = useState(false);
    const [vehicleFilter, setVehicleFilter] = useState({
        page: 1,
        fetchedCount: 0,
    });


    const handleChange = (e) => {
      setDriverPayload((prev) => {
        const updatedPayload = { ...prev, [e.target.name]: e.target.value };
        // Return the updated payload without changes to capacityInKg
        return updatedPayload;
      });
    };

    const handleDropChange = (value, name) => {
        // console.log(value, name);
        setDriverPayload((prev) => ({ ...prev, [name]: value.value }));
    };
    

    const fetchData = async (body) => {
        if (sharedData.id) {
            try {
                const result = await handlePostApi('/vehicle/getVehiclesAllocatedToDriver', { ...body });
                if (result.success) {
                    const selectedVehicle = result.data.data.map((ele) => ({ label: ele.vehicleNo, value: ele._id, ...ele }));
                    // console.log('selectedVehicle', selectedVehicle)
                    setSelectedVehicle(selectedVehicle)
                }
                // console.log('api response ', result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };


    useEffect(() => {
        dispatch(getAreasList({
            page: 1,
            limit: 10,
        }))
        dispatch(getVehiclesList({
            page: vehicleFilter.page,
            limit: 10, // or whatever limit you are using
        }));
    }, [])

    useEffect(() => {
      if (sharedData.id) {
        fetchData({ _id: sharedData.id });
        let temp = {...defaultDriverPayload};
        let selectedDriver = driverlisting && driverlisting?.length > 0 && driverlisting.find((item) => item._id === sharedData.id);
        // console.log('selectedDriver', selectedDriver);
        if (selectedDriver) {
          temp.name = selectedDriver?.name;
          temp.email = selectedDriver?.email;
          temp.mobile = selectedDriver?.mobile;
          temp.userType = "driver";
          temp.maxTripDuration = selectedDriver.settings ? selectedDriver.settings?.maxTripDuration : "";
          temp.earliestTripTime = selectedDriver.settings ? selectedDriver.settings?.earliestTripTime : "";
          temp.latestTripTime = selectedDriver.settings ? selectedDriver.settings?.latestTripTime : "";
          temp.waitingTime = selectedDriver.settings ? selectedDriver.settings?.waitingTime : "";
          temp.driverRestTime = selectedDriver.settings ? selectedDriver.settings?.driverRestTime : "";
          temp.areaId = selectedDriver.settings && selectedDriver.settings?.areaId ? selectedDriver.settings.areaId : [];
          if (selectedDriver.settings?.areaId && selectedDriver.settings.areaId.length > 0) {
            let newArea = [...areaListing];
            newArea[newArea.findIndex((area) => area.value === selectedDriver.settings.areaId[0])].isDisabled = true;
            setAreaListing(newArea);
          }
          temp.secondaryAreaId = selectedDriver.settings && selectedDriver.settings?.secondaryAreaId ? selectedDriver.settings.secondaryAreaId : [];
          if (selectedDriver.settings?.secondaryAreaId && selectedDriver.settings.secondaryAreaId.length > 0) {
            let newArea = [...areaListing];
            newArea[newArea.findIndex((area) => area.value === selectedDriver.settings.secondaryAreaId[0])].isDisabled = true;
            setAreaListing(newArea);
          }
          temp.othersAreaId = selectedDriver.settings && selectedDriver.settings?.othersAreaId ? selectedDriver.settings.othersAreaId : [];
          if (selectedDriver.settings?.othersAreaId && selectedDriver.settings.othersAreaId.length > 0) {
            let newArea = [...areaListing];
            newArea.map((area) => {
              if (selectedDriver.settings.othersAreaId.find((item) => item.value === area.value)) {
                area.isDisabled = true;
              }
            });
            setAreaListing(newArea);
          }
          temp.eligibleForVehicle = selectedDriver.settings ? selectedDriver.settings.eligibleForVehicle : "";
        }
        setDriverPayload(temp);
      }else{
        setSelectedVehicle([]);
        setDriverPayload(defaultDriverPayload);
      }
    }, [sharedData]);

    useEffect(()=> {
        if(listing && listing?.length > 0){
            const updatedListing = listing.map((item, index) => {
                return ({ ...item, value: item.areaId, label: item.areaDescription, isDisabled: false });
            });
            setAreaListing(updatedListing);
        }

    },[listing])

    // // When Redux state updates (new data fetched), update vehicleFilter
    useEffect(() => {
        if (vehicleListing?.length) {
            // console.log('vehicleListing==>', vehicleListing, vehicleOption, vehicleCount);
            let temp = [...vehicleOption];
            temp.splice((vehicleFilter.page - 1), 1, vehicleListing);
            setVehicleOption(temp);
            // console.log('vehicleListing-temp===>', temp);
        }
    }, [vehicleListing, vehicleCount]);

    // Handle scroll to load more
    const handleScrollToBottom = () => {
        // console.log('Scrolled to bottom', vehicleFilter);
        if (((vehicleFilter.page) * 10) < vehicleCount) {
            // console.log('Scrolled to bottom==>', (vehicleFilter.page)*10);
            setVehicleFilter(prevState => ({
                ...prevState,
                page: prevState.page + 1, // Increment page number for the next API call
            }));
            dispatch(getVehiclesList({
                page: vehicleFilter.page + 1,
                limit: 10, // or whatever limit you are using
            }));
        }
    };


    const handleValidationAddDriver = (driverPayload) => {
      let errors = {};
      if (!driverPayload.name) {
        errors.name = "Please enter name";
      }
      if (driverPayload.name.match(/[^a-zA-Z ]/g) || driverPayload.name.length >= 50) {
        errors.name = "Name should be in characters only and length should be less than 50";
      }
      if (!driverPayload.email) {
        errors.email = "Please enter email";
      }
      if (valid_email(driverPayload.email) == false) {
        errors.email = "Please enter valid email";
      }
      if (!driverPayload.mobile) {
        errors.mobile = "Please enter phone";
      }
      if (valid_mobile(driverPayload.mobile) == false) {
        errors.mobile = "Please enter valid phone";
      }
      if (!driverPayload.maxTripDuration) {
        errors.maxTripDuration = "Please enter max trip duration";
      }
      if (!driverPayload.earliestTripTime) {
        errors.earliestTripTime = "Please enter earliest trip time";
      }
      if (!driverPayload.latestTripTime) {
        errors.latestTripTime = "Please enter latest trip time";
      }
      if(!driverPayload.waitingTime) {
        errors.waitingTime = "Please enter waiting Time";
      }
      if(!driverPayload.driverRestTime) {
        errors.driverRestTime = "Please enter driver Rest Time";
      }
      if(driverPayload.vehicles.length === 0) {
        errors.vehicles = "Please select atleast one vehicle";
      }
      if (driverPayload.areaId.length === 0) {
        errors.areaId = "Please select area";
      }
      if (driverPayload.secondaryAreaId.length === 0) {
        errors.secondaryAreaId = "Please select secondary area";
      }
    //   if (driverPayload.othersAreaId.length === 0) {
    //     errors.othersAreaId = "Please select others area";
    //   }

      setError(errors);

      return errors;
    };

    const handleAddDriver = async () => {
        const payload = {...driverPayload, vehicles: selectedVehicle}
        const errors = handleValidationAddDriver(payload);
        if (Object.keys(errors).length > 0) {
          // console.error("Validation errors:", errors);
          return;
        }
        // console.log("add driver", driverPayload);
        try {
            const result = await handlePostApi("/user/addUser", { ...payload });
            // console.log("api result====>>>>", result);
            if (result.success) {
              showNotification({message: result.message, title: "Success!!", autohide: true, showInput: false});
              dispatch(
                getUsers({
                  page: 1,
                  limit: 10,
                  searchType: "",
                  searchValue: "",
                  userType: "driver",
                  type: "driver",
                })
              );
              setError(defaultDriverError)
              setDriverPayload(defaultDriverPayload)
              onClose();
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    } 

    const handleEditDriver = async () => {
        const payload = {_id : sharedData.id , updateDetails : {...driverPayload, vehicles: selectedVehicle, isVehicleUpdated : isVehicleUpdated}}
        const errors = handleValidationAddDriver({...driverPayload, vehicles: selectedVehicle});
        if (Object.keys(errors).length > 0) {
          // console.error("Validation errors:", errors);
          return;
        }
        // console.log("edit driver", driverPayload);
        try {
            const result = await handlePostApi("/user/editUser", { ...payload });
            // console.log("api result====>>>>", result);
            if (result.success) {
              showNotification({ message: result.message, title: "Success!!", autohide: true, showInput: false });
              dispatch(
                getUsers({
                  page: sharedData.pageCount,
                  limit: sharedData.pageLimit,
                  searchType: "",
                  searchValue: "",
                  userType: "driver",
                  type: "driver",
                })
              );
              setError(defaultDriverError);
              setDriverPayload(defaultDriverPayload);
              onClose();
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    }

    // console.log('areaListing==>', areaListing)

    return (
      <CustomViewModal
        heading={sharedData.id ? "Edit Delivery Man" : "Add Delivery Man"}
        show={show}
        onClose={() => {
          setError(defaultDriverError);
          setDriverPayload(defaultDriverPayload);
          onClose();
        }}
        size={"lg"}
      >
        <Container fluid className="">
          <Row className="addBg p-2">
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Name<span className="asterick"> * </span> :{" "}
                </span>
                <Input error={error.name} name="name" value={driverPayload.name} placeholder="Enter Name" onChange={(e) => handleChange(e)} />
              </Row>
            </Col>
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Mobile Number<span className="asterick"> * </span> :{" "}
                </span>
                <Input error={error.mobile} type="number" name="mobile" value={driverPayload.mobile} placeholder="Enter Mobile Number" onChange={(e) => handleChange(e)} />
              </Row>
            </Col>
          </Row>
          <Row className="addBg p-2 mt-2">
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Email<span className="asterick"> * </span> :{" "}
                </span>
                <Input error={error.email} name="email" value={driverPayload.email} placeholder="Enter Email" onChange={(e) => handleChange(e)} />
              </Row>
            </Col>
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Max Trip Duration<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => handleDropChange(value, "maxTripDuration")}
                    options={timeSlots}
                    styles={colourStyles}
                    error={error.maxTripDuration}
                    value={timeSlots.find((el) => el.value === driverPayload.maxTripDuration)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="addBg p-2 mt-2">
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Earliest Start Time<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => handleDropChange(value, "earliestTripTime")}
                    options={timeSlots}
                    styles={colourStyles}
                    error={error.earliestTripTime}
                    value={timeSlots.find((el) => el.value === driverPayload.earliestTripTime)}
                  />
                </div>
              </Row>
            </Col>
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Latest Trip End Time<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => handleDropChange(value, "latestTripTime")}
                    options={timeSlots}
                    styles={colourStyles}
                    error={error.latestTripTime}
                    value={timeSlots.find((el) => el.value === driverPayload.latestTripTime)}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="addBg p-2 mt-2">
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Waiting Time (mins)<span className="asterick"> * </span> :{" "}
                </span>
                <Input error={error.waitingTime} type="number" name="waitingTime" value={driverPayload.waitingTime} placeholder="Enter Waiting Time" onChange={(e) => handleChange(e)} />
              </Row>
            </Col>
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Rest Time (mins) <span className="asterick"> * </span> :{" "}
                </span>
                <Input error={error.driverRestTime} type="number" name="driverRestTime" value={driverPayload.driverRestTime} placeholder="Enter Rest Time" onChange={(e) => handleChange(e)} />
              </Row>
            </Col>
          </Row>
          <Row className="addBg p-2 mt-2">
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center" style={{ flexWrap: "nowrap" }}>
                <span className="custSpan" style={{ width: "200px" }}>
                  Vehicle<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "-webkit-fill-available" }}>
                  <DropDown
                    options={
                      vehicleOption.length
                        ? vehicleOption
                            .flat()
                            .filter((vehicle) =>
                              sharedData?.id
                                ? vehicle.allocatedTo_id === sharedData.id || !vehicle.hasOwnProperty("allocatedTo_id") || vehicle.allocatedTo_id === null || vehicle.allocatedTo_id === ""
                                : !vehicle.allocatedTo_id
                            )
                            .map((vehicle) => ({
                              label: vehicle.vehicleNo,
                              value: vehicle._id,
                              ...vehicle,
                            }))
                        : []
                    }
                    error={error.vehicles}
                    isMulti
                    isLoading={vehicleloader}
                    value={selectedVehicle} // Add value prop to bind selected options
                    onMenuScrollToBottom={handleScrollToBottom} // Trigger scroll to load more
                    onChange={(selectedOptions) => {
                      setIsVehicleUpdated(true);
                      setSelectedVehicle(selectedOptions);
                    }}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="addBg p-2 mt-2">
            <Col className="">
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Primary Area Name<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => {
                      let newArea = [...areaListing];
                      newArea[newArea.findIndex((area) => area.value === value.value)].isDisabled = true;
                      if (Array.isArray(driverPayload.areaId) && driverPayload.areaId.length == 1) {
                        newArea[newArea.findIndex((area) => area.value === driverPayload.areaId[0])].isDisabled = false;
                      }
                      setAreaListing(newArea);
                      handleDropChange({ ...value, value: [value.value] }, "areaId");
                    }}
                    options={areaListing}
                    styles={colourStyles}
                    error={error.areaId}
                    value={areaListing.find((el) => el.value === driverPayload.areaId[0])}
                  />
                </div>
              </Row>
            </Col>
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center">
                <span className="custSpan">
                  Secondary Area Name<span className="asterick"> * </span> :{" "}
                </span>
                <div style={{ width: "200px" }}>
                  <DropDown
                    onChange={(value) => {
                      let newArea = [...areaListing];
                      newArea[newArea.findIndex((area) => area.value === value.value)].isDisabled = true;
                      if (Array.isArray(driverPayload.secondaryAreaId) && driverPayload.secondaryAreaId.length == 1) {
                        newArea[newArea.findIndex((area) => area.value === driverPayload.secondaryAreaId[0])].isDisabled = false;
                      }
                      setAreaListing(newArea);
                      handleDropChange({ ...value, value: [value.value] }, "secondaryAreaId");
                    }}
                    options={areaListing}
                    styles={colourStyles}
                    error={error.secondaryAreaId}
                    value={areaListing.find((el) => el.value === driverPayload.secondaryAreaId[0])}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="addBg p-2 mt-2">
            <Col>
              <Row className="px-2 d-flex justify-content-between align-items-center" style={{ flexWrap: "nowrap" }}>
                <span className="custSpan" style={{ width: "200px" }}>
                  Other Area Name :{" "}
                </span>
                <div style={{ width: "-webkit-fill-available" }}>
                  <DropDown
                    onChange={(value) => {
                      let newArea = [...areaListing];
                      newArea.map((area) => {
                        if (value.find((item) => item.value === area.value)) {
                          area.isDisabled = true;
                        } else {
                          if (Array.isArray(driverPayload.secondaryAreaId) && driverPayload.secondaryAreaId.length == 1 && area.value === driverPayload.secondaryAreaId[0]) {
                            area.isDisabled = true;
                          } else if (Array.isArray(driverPayload.areaId) && driverPayload.areaId.length === 1 && area.value === driverPayload.areaId[0]) {
                            area.isDisabled = true;
                          } else {
                            area.isDisabled = false;
                          }
                        }
                      });
                      setAreaListing(newArea);
                      let newValue = value.map((item) => item.value);
                      handleDropChange({ value: newValue }, "othersAreaId");
                    }}
                    options={areaListing}
                    styles={colourStyles}
                    isMulti={true}
                    error={error.othersAreaId}
                    value={driverPayload.othersAreaId.map((el) => areaListing.find((area) => area.value === el))}
                  />
                </div>
              </Row>
            </Col>
          </Row>
          <Row className="mt-3 p-2 d-flex justify-content-around align-items-center">
            <button
              className="customBtn"
              onClick={() => {
                if (sharedData.id) {
                  handleEditDriver();
                } else {
                  handleAddDriver();
                }
              }}
            >
              {sharedData.id ? "Edit Delivery Man" : "Add Delivery Man"}
            </button>
          </Row>
        </Container>
      </CustomViewModal>
    );
}

export default AddDriver;