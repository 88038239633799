import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAreasList } from '../../state/redux/actions/index';
import CustomPageLayout from '../Utils/CustomPageLayout/CustomPageLayout';
import { CustomTable } from '../Utils/Inputs';

const AreaManagement = () => {
    const dispatch = useDispatch();
    const { areaLists: listing, loading: loader, message } = useSelector((state) => state.getAreasRed);
    const [pageCount, setPageCount] = useState(1);
    const [count, setCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(10); // Set your desired page limit

    useEffect(() => {
        dispatch(getAreasList({ page: pageCount, limit: pageLimit }));
    }, [dispatch, pageCount, pageLimit]);

    const tableHeader = ["S. No", "Area Description", "Area ID", "Area Code"];

    const tableRows =
      (listing &&
        listing.map((item, index) => {
          let items = [];
          items.push(<>{index + 1}</>, <>{item.areaDescription || "-"}</>, <>{item.areaId || "-"}</>, <>{item.areaCode || "-"}</>);

          return items;
        })) ||
      [];

    return (
      <CustomPageLayout title="Area Management">
        <div>
            <CustomTable
            header={tableHeader}
            rows={tableRows ? tableRows : []}
            />
        </div>
      </CustomPageLayout>
    );
}

export default AreaManagement;