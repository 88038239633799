import React from "react";

export default function CustomPageLayout({ title, addTitleButton, addHandleClick, children, buttonChildern=null }) {
  return (
    <div className="contentpanel">
      <div className="cust-row flex-algn-cent">
        <div className="cust-col-5">
          <h1 className="title">{title}</h1>
        </div>
        <div className="cust-col-5 flex-jc-end">
          {buttonChildern} &nbsp;&nbsp;
          {addTitleButton && <button className="myMapBtnGrey" onClick={addHandleClick}>
            {addTitleButton}
          </button>}
          
        </div>
      </div>
      <hr className="bgg" />
      <div className="cust-container">{children}</div>
    </div>
  );
}
