import React, { useEffect } from 'react';
import pfs from './../../static/images/icon/sidebar/updated/pure.jpg'
import pfscropped from './../../static/images/icon/sidebar/updated/pfsnew.jpeg'
import bg from './../../static/images/icon/sidebar/updated/yb.jpg'
import { cylinderData } from '../../state/redux/actions/index';
import { useDispatch, useSelector } from 'react-redux'
import beep from './../../static/images/icon/sidebar/updated/jingle.mpeg'
import $ from 'jquery'
import moment from 'moment'

// const data = {
//     "_id": "66a1fb39abd6b63dec44961b",
//     "qr_id": "https://betabpcl.lynkit.in?id=060923591390342",
//     "__v": 0,
//     "createdAt": "2023-09-01T13:10:16.249Z",
//     "dt_created": "2023-09-01T00:00:00.000Z",
//     "gross_weight": 29.8,
//     "isDelivered": true,
//     "plantId": "GUID",
//     "plant_sub_id": 111,
//     "qrId": "060923591390342",
//     "qr_image": "blob",
//     "transport_veh_num": "TS 09 GT 8888",
//     "updatedAt": "2023-10-05T13:52:36.053Z",
//     "updated_at": "2023-09-01",
//     "dateOfDelivery": "2023-10-05T13:52:36.035Z", "net_weight": 29.8, "tare_weight": 29.8,
// }

const CylinderMapping = () => {
    let audio = new Audio(beep)
    const dispatch = useDispatch()
    const cylinderInformation = useSelector((state) => state.getCylinderRed.cylinderData);

    // console.log('cylinderInformation', cylinderInformation)

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const getKey = query.get('id')
        let temp = {
            qrId: getKey
        }
        dispatch(cylinderData(temp))
    }, [])
    // useEffect(() => {
    //     $(document.body).one('click', function (e) {
    //         if (cylinderInformation && cylinderInformation?.gross_weight) {
    //             audio.play()
    //         }

    //     });
    // }, [])
    useEffect(() => {
        // console.log('cylinderInformation:', cylinderInformation);
        if (cylinderInformation && cylinderInformation.gross_weight) {
            // console.log('Adding click listener');
            const handleClick = () => {
                // console.log('Playing audio');
                audio.play();
            };
            $(document.body).one('click', handleClick);

            return () => {
                // console.log('Removing click listener');
                $(document.body).off('click', handleClick);
            };
        }
    }, [cylinderInformation]);
    return (
        <>
            <div className='cylinerMap'>
                <img src={bg} className='ybg' alt='' />
                <div className='cylinderInnerCont'>
                    <div className='cicCenter'>
                        <img src={pfs} alt='' className='pfsImgNew' />
                    </div>
                    <div className='cicRight'>
                        {/*previous condition (!cylinderInformation || (!cylinderInformation.gross_weight && (!cylinderInformation.isDelivered || !cylinderInformation.dt_created)) */}
                        {(!cylinderInformation) ?
                            <div className='cic50' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                                <span style={{ textDecoration: 'none', fontWeight: '700', fontSize: '18px', color: 'red' }}>No information Available</span>
                            </div>
                            :
                            <>

                                {cylinderInformation && cylinderInformation.gross_weight ?
                                    <div className='cic50' style={{ marginTop: '10px' }}>
                                        <div className='cicSpa' style={{ width: '55%' }}>
                                            <span className='cicSpan'>Gross Weight :</span>
                                        </div>
                                        <div className='cicVal' style={{ width: '45%' }}>
                                            <span className='cicValue' style={{ textDecoration: 'none' }}>{cylinderInformation.gross_weight + ' kg'}</span>
                                        </div>
                                    </div> :
                                    null
                                }
                                {cylinderInformation && cylinderInformation.net_weight ?
                                    <div className='cic50' style={{ marginTop: '10px' }}>
                                        <div className='cicSpa' style={{ width: '55%' }}>
                                            <span className='cicSpan'>Net Weight :</span>
                                        </div>
                                        <div className='cicVal' style={{ width: '45%' }}>
                                            <span className='cicValue' style={{ textDecoration: 'none' }}>{cylinderInformation.net_weight + ' kg'}</span>
                                        </div>
                                    </div> :
                                    null
                                }
                                {cylinderInformation && cylinderInformation.tare_weight ?
                                    <div className='cic50' style={{ marginTop: '10px' }}>
                                        <div className='cicSpa' style={{ width: '55%' }}>
                                            <span className='cicSpan'>Tare Weight :</span>
                                        </div>
                                        <div className='cicVal' style={{ width: '45%' }}>
                                            <span className='cicValue' style={{ textDecoration: 'none' }}>{cylinderInformation.tare_weight + ' kg'}</span>
                                        </div>
                                    </div> :
                                    null
                                }

                                {/* {cylinderInformation && cylinderInformation.createdAt ?
                                    <div className='cic50' style={{ marginTop: '10px' }}>
                                        <div className='cicSpa' style={{ width: '55%' }}>
                                            <span className='cicSpan'>Created Date :</span>
                                        </div>
                                        <div className='cicVal' style={{ width: '45%' }}>
                                            <span className='cicValue' style={{ textDecoration: 'none' }}>{moment(cylinderInformation.createdAt).format("DD-MM-YYYY")}</span>
                                        </div>
                                    </div> :
                                    null
                                } */}
                                {cylinderInformation && cylinderInformation?.isDelivered && cylinderInformation.dt_created ?
                                    <div className='cic'>
                                        <div className='cic50'>
                                            <div className='cicSpa' style={{ width: '55%' }}>
                                                <span className='cicSpan'>Cylinder Filling Date :</span>
                                            </div>
                                            <div className='cicVal' style={{ width: '45%' }}>
                                                <span className='cicValue' style={{ textDecoration: 'none' }}>{cylinderInformation && cylinderInformation.dt_created ? moment(cylinderInformation.dt_created).format("DD-MM-YYYY") : '-'}</span>
                                            </div>
                                        </div>

                                    </div>
                                    : null}
                                {cylinderInformation && cylinderInformation.plantId ?
                                    <div className='cic50' style={{ marginTop: '10px' }}>
                                        <div className='cicSpa' style={{ width: '55%' }}>
                                            <span className='cicSpan'>Plant Id :</span>
                                        </div>
                                        <div className='cicVal' style={{ width: '45%' }}>
                                            <span className='cicValue' style={{ textDecoration: 'none' }}>{cylinderInformation.plantId}</span>
                                        </div>
                                    </div> :
                                    null
                                }
                            </>
                        }

                    </div>
                </div>
            </div>
        </>
    );
};
export default CylinderMapping;